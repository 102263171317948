<template>
   <scan-view></scan-view>
</template>


<script>

import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
import ScanView from '@/client/applications/talron/views/Scan.vue'

export default {
    layout    : 'default',
    middleware: {

    },
    setup(props) {
        let {meta}     = useMeta({title: useI18n().t('talron.defaultPage.mainTitle')}).meta;

        return {meta};
    },
    data() {

        return {

        }
    },
    components: {
        ScanView,
    },
    computed  : {
    },
    methods   : {

    },
    mounted() {

    },

    watch: {},

}
</script>

<style lang="scss" scoped>

</style>





