<template>
    <div class="page talron uk-animation-fade replace-external-page" >
        <layout-process
            :titleClickAction="cancelReplaceRequest"
            :title="translate('talron.replace.talron.processTitle')" >
            <div class="process-steps">
                <div class="process-step">
                    <span class="process-step__icon">
                        <img :src="getDynamicAssetUrl('images/icons/replace-battery-crossed.svg')"
                             alt=""/>
                    </span>
                    <h2 class="process-step__title tr-font-bold tr-font-m ">{{translate('talron.replace.talron.badBatteryTitle')}}</h2>
                    <span class="process-step__content-item process-step__content-item--strong">
                        <span> {{translate('talron.replace.talron.badBatterySerialNumberLabel')}} : </span><span>{{batteryInfo.serialNumber}}</span>
                    </span>
                    <span class="process-step__content-item">
                        <span> {{translate('talron.replace.talron.badBatteryInWarantyUntil')}} : </span><span>{{batteryInfo.warranty.toDate}}</span>
                    </span>
                </div>
                <div class="process-step__separator">
                    <span class="process-step__separator-icon" uk-icon="long-arrow-left"></span>
                </div>
                <div class="process-step">
                    <span class="process-step__icon">
                        <img :src="getDynamicAssetUrl('images/icons/battery-new.svg')"
                             alt=""/>
                    </span>
                    <h2 class="process-step__title tr-font-bold tr-font-m uk-margin-remove-bottom">{{translate('talron.replace.talron.newBatteryTitle')}}</h2>

                    <div class="replace-form-container" v-if="displayMode === 'recieveCode'">

                         <base-form

                        class="talron-replace-form"
                        method="patch"
                        :action="'battery/replace/talron'"
                        v-model="batteryFormData"
                        @form:submitSuccess="handleUpdatedReplacementRequest"
                        :config="formConfig"></base-form>

                        <div class="mobile-cancel-link uk-hidden@l">
                            <a href="#" @click.prevent="cancelReplaceRequest">
                                {{translate('talron.general.cancelActionLinkDesktop')}}
                            </a>
                        </div>
                    </div>


                    <div class="replace-candidate-status replace-candidate-status--error"
                         ref="candidateErrorMessage"
                         role="alert"
                         v-if="displayMode === 'candidateError'">
                        <div class="replace-candidate-text">
                             <span class="replace-candidate-status__title">
                            {{translate('talron.replace.talron.replaceCandiadteErrorTitle', {batterySerialNumber: batteryInfo.serialNumber})}}
                        </span>
                            <span class="replace-candidate-status__explain" v-if="replaceCandidateErrorText">
                                {{replaceCandidateErrorText}}
                            </span>
                            <span class="replace-candidate-status__explain" v-else>
                                {{translate('talron.replace.talron.replaceCandidateErrorExplain')}}
                            </span>

                        </div>

                        <form-button class="replace-candidate-status-button" @click="displayMode = 'recieveCode'">
                            {{translate('talron.replace.talron.replaceCandidateErrorCtaAnother')}}
                        </form-button>
                        <a class="replace-candidate-status-cancel" href="#" @click.prevent="cancelReplaceRequest">
                            {{translate('talron.replace.talron.replaceCandidateErrorCtaCancel')}}
                        </a>
                    </div>

                    <div class="replace-candidate-status replace-candidate-status--success"
                         ref="candidateSuccessMessage"

                         v-if="displayMode === 'candidateSuccess'">
                        <div class="replace-candidate-text" role="alert" >
                             <span class="replace-candidate-status__title">
                            <span class="replace-candidate-status__title-icon" uk-icon="check-circle"></span>
                            <span class="replace-candidate-status__title-text">{{translate('talron.replace.talron.replaceCandidateSuccessTitle', {batterySerialNumber:  batteryFormData.batteryBarcode})}}</span>
                        </span>
                             <span class="replace-candidate-status__explain">{{translate('talron.replace.talron.replaceCandidateSuccessExplain', {warrantyExtendedToData: batteryInfo.warranty.toDate})}}</span>
                        </div>

                        <form-button class="replace-candidate-status-button" @click="dispatchReplacement">
                            {{translate('talron.replace.talron.replaceCandidateSuccessCtaConfirm')}}
                        </form-button>
                        <a class="replace-candidate-status-cancel" href="#" @click.prevent="cancelReplaceRequest">
                            {{translate('talron.replace.talron.replaceCandidateSuccessCtaCancel')}}
                        </a>
                    </div>

                </div>
            </div>

            <div class="desktop-cancel-link uk-visible@l">
                <a href="#" @click.prevent="cancelReplaceRequest">
                    {{translate('talron.general.cancelActionLinkDesktop')}}
                </a>
            </div>

        </layout-process>
    </div>
</template>

<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import {useStore, mapGetters} from "vuex";
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
import {computed} from 'vue';
export default {
    layout    : 'default',
    middleware: {
        requireTalronUser       : {},
        requireBattery          : {},
        requireReplacementGUID  : {},
    },

    setup (props, context) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        let {meta}                                 = useMeta({title: useI18n().t('talron.replace.talron.pageTitle')}).meta

        return {
            asyncOps, asyncOpsReady, asyncStatus,meta
        }
    },
    data() {

        return {
            displayMode: 'recieveCode', //enum ['recieveCode','candidateError','candidateSuccess', 'processFinished']
            batteryFormData : {
                batteryBarcode: '',
                replacementGUID: computed(() => this.replacementGUID),
                userId: computed(() => this.userId),
            },
            expectedWarranty: '-',
            replaceCandidateErrorText : false,
            formConfig: {
                fields : {
                    batteryBarcode: {
                        name        : 'batteryBarcode',
                        label       : false,
                        placeholder : 'talron.scan.scanFieldPlaceholder',
                        type        : 'TalronBatteryBarcodeScandit',
                       // iconEnd     : "barcode",
                        wrapperStyle: 'max-width: 320px; margin: 0 auto;',
                        validation  : {
                            required      : {},
                            batteryBarcode: {},
                        },
                    },
                    userId    : {
                        name       : 'userId',
                        label      : false,
                        placeholder: 'talron.login.personalIdFieldPlaceholder',
                        type       : 'hidden',
                    },
                    replacementGUID: {
                        name: 'replacementGUID',
                        type: 'hidden',
                    }
                },
                buttons: {
                    'submit': {
                        'type': 'alt',
                        'text': 'talron.login.buttonCta',
                        'style': 'margin-left: auto; margin-right: auto;display: block;'
                    }
                }
            },
            asyncData: {

            },
        }
    },
    components: [

    ],
    computed  : {
        ...mapGetters({
              'userName'        : 'talron/userName',
              'userId'          : 'talron/userId',
              'userPersonalId'  : 'talron/userPersonalId',
              'batteryInfo'     : 'talron/battery',
              'replacementGUID' : 'talron/replacementGUID',
          }),

    },
    methods   : {
        async cancelReplaceRequest () {
            let payload = {
                userId: this.userId,
                replacementGUID : this.replacementGUID
            }

            this.$store.commit('ui/showGlobalSpinner', this.translate('talron.general.loadingSpinnerTextShort'));
            let result = await this.asyncOps.asyncCall(
                'battery/replace/talron/' + this.replacementGUID,
                payload,
                {method: 'delete'}
            )
            this.$store.commit('talron/clearReplacementGUID');
            this.$store.commit('ui/hideGlobalSpinner');

            this.$router.back();

        },
        async handleUpdatedReplacementRequest (eventData){
            let input = eventData.result;

            if (input.isError) {
                this.$s.ui.notification(this.translate('talron.replace.talron.replaceUpdateException'), 'error');
                return;
            }


            if (input.data.status === '1' || input.data.status === 1) {
                this.displayMode = 'candidateSuccess';
                this.expectedWarranty = input.data.expectedEndDateDisplayString;
                // focus forr accesibility
                try {
                    this.$refs.candidateSuccessMessage.focus();
                } catch (e) {

                }
                return;
            }

            if (input.data.status === '0' || input.data.status === 0) {
                this.replaceCandidateErrorText = input.data.rejectionReason ? input.data.rejectionReason : false;
                this.displayMode = 'candidateError';
                // focus forr accesibility
                try {
                    this.$refs.candidateErrorMessage.focus();
                } catch (e) {

                }
            }
        },
        async replaceBatteryInState(barcode) {
            let result = await this.asyncOps.asyncCall(
                'battery/'+barcode,
                {userId : this.userId, batteryBarcode: barcode},
                {method: 'get'}
            );

            if ( result.isError || result.data.status === '0' || result.data.status === 0) {
                this.$s.ui.notification(this.translate('talron.general.fetchBatteryInfoError'), 'error');
                return;
            }

            this.$store.commit('talron/setBattery', {...result.data});

        },
        async dispatchReplacement() {
            let payload = {
                userId: this.userId,
                replacementGUID : this.replacementGUID
            }

            this.$store.commit('ui/showGlobalSpinner', this.translate('talron.general.loadingSpinnerText'));
            let result = await this.asyncOps.asyncCall(
                'battery/replace/talron/' + this.replacementGUID + '/dispatch',
                payload,
                {method: 'post'}
            )
            this.$store.commit('ui/hideGlobalSpinner');

            if (result.isError) {
                this.$s.ui.notification(this.translate('talron.general.actionFailed'), 'error');
                return;
            }


            if (result.data.status == 0 || result.data.status == '0') { // this is a different case. for now, we handle all errors the same way tho
                let errorText =  typeof result.data.rejectionReason === 'string' ? result.data.rejectionReason : this.translate('talron.general.actionFailed');
                return this.$s.ui.notification(errorText, 'error');
            }

            await this.replaceBatteryInState(this.batteryFormData.batteryBarcode);

            this.$router.push({name: 'replace-talron-success'});
        }
    },
    mounted() {
        this.$store.commit('talron/appActionState', 'default');
    },
    unmounted() {
        this.$store.commit('talron/appActionState' , 'default');
        this.displayMode = 'recieveCode';
    }
}
</script>

<style lang="scss" scoped>

.process-steps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    @media (min-width: 1200px) {
        flex-direction: row;
        gap: 40px;
    }
}

.process-step {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    &__separator {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 40px;
        margin-bottom: 40px;

        @media (min-width: 1200px) {
            width: auto;
            height: 400px;
            margin: 0;
        }

        &::after, &::before {
            content: ' ';
            position: absolute;
            height: 1px;
            width: calc(50% - 20px);
            top: 50%;
            background-color: #dddddd;

            @media (min-width: 1200px) {
                width: 1px;
                height: 120px;
                inset-inline-start: calc(50% - 2px);
            }
        }

        &::before {
            inset-inline-start: 0;

            @media (min-width: 1200px) {
                top: calc(50% - 140px);
                inset-inline-start: calc(50% - 2px);
                bottom: auto;
                width: 1px;
            }
        }

        &::after {
            inset-inline-end: 0;
            @media (min-width: 1200px) {
                top: auto;
                bottom: calc(50% - 140px);
                inset-inline-end: auto;
            }
        }
    }

    &__separator-icon {
        margin-top: 10px;
        margin-bottom: 10px;
        transform: rotate(-90deg);
        color: var(--global-primary-background-dark);
        display: block;
        z-index: 4;

        @media (min-width: 1200px) {
            transform: none;
            width: 45px;
            height: 16px;
        }
    }

    &__icon {
        display: inline-block;
        width: 34px;
        height: 34px;
        flex: 34px;
        height: auto;

        img {
            width: 34px;
            height: auto;
            object-fit: contain;
        }
    }

    &__title {
        font-weight: bold;
        margin: -5px 0 0 0;
        color: var(--global-primary-background-dark);
    }

    &__content-item {
        text-align: center;

        &--strong {
            font-weight: 500;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        gap: 16px;
    }

    &__cancel {
        font-weight: bold;
        font-size: var(--font-s);
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.mobile-cancel-link {
    margin-top: 20px;
    text-align: center;

    a {
        color: var(--global-link-color);
        text-decoration: underline;
        font-weight: 700;

        &:hover {
            text-decoration: none;

        }
    }
}

.desktop-cancel-link {
    margin-top: 50px;
    text-align: center;

    a {
        color: var(--global-link-color);
        text-decoration: underline;
        font-weight: 700;

        &:hover {
            text-decoration: none;

        }
    }
}

.replace-form-container {
    width: 500px;

    @media (max-width: 1200px) {
        width: 300px;
        max-width: 100%;
    }
}
.success-header {
    color: var(--global-success-background);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.success-icon-wrapper{

}

.success-icon {

}

.replace-success-title {
    font-size: var(--font-l);
    font-weight: bold;
    color: var(--global-success-background);
    margin-top: 12px;
    margin-bottom: 30px;
}

.success-content {
    counter-reset: test;

    .success-list {
        list-style: none;
        padding: 0;
        margin: 0;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding: 25px 0;
    }

    .success-list-item {
        margin-bottom: 40px;

        &:last-of-type {
            margin-bottom: 0;
        }

        &__number {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 26px;
            width: 26px;
            flex: 0 0 26px;
            border: 2px solid var(--global-color);
            border-radius: 50%;
            text-align: center;
            font-weight: bold;
            font-size: 14px;
            line-height: 0px;
            color: var(--global-color);
        }

        &__content {
            display: inline-block;
            text-align: justify;
            flex: 100%;
        }
    }
}

.back-button-gruop {
    margin-top: 20px;
}

.replace-candidate-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .replace-candidate-text {
        padding          : 10px 15px;
        margin-bottom    : 20px;

        .replace-candidate-status__title {
            display       : block;
            font-weight   : bold;
            font-size     : var(--font-s);
            margin-bottom : 5px;
        }
        .replace-candidate-status__title-icon {
            font-size: var(--font-xs);
            margin-inline-end: 6px;
            display: inline-block;
            height: 14px;
            width: 14px;
        }
        .replace-candidate-status__explain {
            display     : block;
            font-weight : 600;
            font-size   : var(--font-s);
        }
    }

    &--error {
        .replace-candidate-text {
            background-color: var(--modal-error-color-light);

            .replace-candidate-status__title {
                color: var(--modal-error-color);
            }
        }
    }

    &--success {
        .replace-candidate-text {
            background-color: var(--global-success-background-light);

            .replace-candidate-status__title {
                color: var(--global-success-background);
            }
        }
    }


    .replace-candidate-status-button {
        margin-bottom: 20px;
    }

    .replace-candidate-status-cancel {
        font-size: var(--font-xs);
        font-weight: bold;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

</style>

<style lang="scss">


</style>



