<template>
  <div class="page talron main-page scan-page">

    <layout-solid-blob title="talron.tos.viewTitle">
      <div class="ac-doc">

        <ol>

          <li>
            <h2>מבוא</h2>
            <ol>
              <li>
                <p>
                  (להלן: "טלרון או "החברה") בעל האתר / אפליקציה ומפעילו היא חברת "טלרון בע"מ.
                </p>
              </li>
              <li>
                <p>
                  פעולה באתר היא כל פעולה המבוצעת באתר / באפליקציה, לקבלת מידע ו/או שירותים המוצעים באתר (להלן: "פעולה")
                </p>
              </li>
              <li>
                <p>
                  הוראות תקנון זה יחולו על כל פעולה שתבוצע על ידי "המשתמש", כהגדרתו להלן, באתר.
                </p>
              </li>
              <li>
                <p>
                  הנך נדרש לקרוא בעיון את תנאי השימוש להלן, לפני התחלת השימוש באתר זה. אם אינך מסכים לתנאי שימוש אילו,
                  חדל מיד משימוש באתר או פנה אלינו כדי שנוכל לסייע לך בדרכים חלופיות לשביעות רצונך.
                </p>
              </li>
              <li>
                <p>
                  תקנון זה מהווה חוזה התקשרות מחייב בין המשתמש ובין טלרון, לכל דבר ועניין. מובהר כי גלישה באתר /
                  אפליקציה ו/או ביצוע פעולות באתר, מהוות הסכמת המשתמש לקבל ולנהוג על פי הוראות התקנון. אם אינך מסכים
                  לתנאי מתנאי תקנון זה, הנך מתבקש לא לעשות כל שימוש באתר. יוברר שכל המבצע פעולה באתר מצהיר כי הנו מודע
                  להוראת תקנון האתר ומקבלם, וכי לא תהא לו ו/או למי מטעמו כל טענה ו/או תביעה כנגד בעלי האתר ו/או מפעיליו
                  ו/או מי מטעמם מלבד טענות הקשורות בהפרת התחייבויות בעלי האתר ו/או מפעיליו על פי תקנון וכללי השתתפות
                  אלו.
                </p>
              </li>
              <li>
                <p>
                  לצורך תקנון זה, "משתמש" - כל אדם, לרבות חברה, רשאי להשתמש באתר, לרבות לבצע פעולות באמצעות האתר, הכל
                  בכפוף למילוי התנאים המצטברים המפורטים להלן:
                </p>
              </li>
              <li>
                <p>
                  המשתמש הנו כשיר לבצע פעולות משפטיות מחייבות; במידה והנך קטין (מתחת לגיל 18) או אינך זכאי לבצע פעולות
                  משפטיות ללא אישור אפוטרופוס, הנך נדרש לידע את הוריך ו/או את האפוטרופוסים החוקיים שלך ("ההורים") בדבר
                  הוראות תקנון זה ולקבל את אישורם לביצוע כל פעילות, מכל סוג שהוא, במסגרת האתר. על ההורים חלה אחריות
                  ליידע את הקטינים המעוניינים לגלוש באתר בדבר התקנון וכן לפקח על פעילות הקטינים בכדי שהקטינים יפעלו על
                  פי תנאי תקנון זה. כל פעילות אשר תבוצע על ידי הקטינים באתר מהווה הסכמה של הקטינים ו/או ההורים לאמור
                  בתנאי תקנון זה .
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>תנאים כלליים</h2>
            <ol>
              <li>
                <p>
                  מובהר כי טלרון רשאית, על פי שיקול דעתה הבלעדי, להפסיק ו/או להשהות את פעילות האתר בכל עת וללא הודעה
                  מראש.
                </p>
              </li>
              <li>
                <p>
                  טלרון שומרת לעצמה את הזכות למנוע את הגישה לאתר ו/או לאפשרות לבטל השתתפות של משתמש שהתנהגותו אינה ראויה
                  והולמת, או שאינה לפי תקנון השימוש, או המנסה לפגוע בניהולו ובפעולתו התקינה של האתר החברה.
                </p>
              </li>
              <li>
                <p>
                  האמור בתקנון זה מתייחס באופן שווה לבני שני המינים, והשימוש בלשון זכר הוא מטעמי נוחות בלבד.
                </p>
              </li>
              <li>
                <p>
                  תקנון זה ניתן לשינוי ו/או עדכון בכל עת ע"י טלרון ו/או מי שפועל מטעמה, על פי שיקול דעתם הבלעדי.
                </p>
              </li>
              <li>
                <p>
                  כותרות הפרקים מובאות לשם נוחות והתמצאות המשתמש ולא ישמשו בפרשנות התקנון.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2>שירותים המוצעים באתר</h2>
            <ol>
              <li>
                <p>
                  אתר / אפליקציית "ממשק ניהול אחריות מצברים" (להלן: "האתר") מספק פלטפורמה נוחה ומקוונת לקבלת ועדכון מידע
                  המיועד לכל החפץ בכך, ומשמש כמרכז מידע עבור גולשים ללא עלות (להלן: "המידע").
                </p>
              </li>
              <li>
                <p>
                  האתר בבעלותה ובניהולה של טלרון מצברים בע"מ, ח.פ.510401169 , כתובת המעיין 7, אזור תעשייה מודיעין.
                </p>
              </li>
            </ol>
          </li>

          <li>
            <h2>הגבלות שימוש באתר</h2>
            <p>מבלי לגרוע מהאמור לעיל, לא יהיה המשתמש רשאי לעשות שימוש באתר, בין היתר, בנוגע לעניינים הבאים:</p>
            <ol>
              <li>
                <p>
                  לעשות כל שימוש שיש בו כדי להוות הפרת זכות יוצרים של אחרים לרבות של החברה.
                </p>
              </li>
              <li>
                <p>
                  לעשות כל שימוש באתר הנוגד הוראת כל דין, רשות מוסמכת או צו שיפוטי.
                </p>
              </li>
              <li>
                <p>
                  לשנות או להשחית בצורה כלשהי את פני האתר או את קוד התוכנה.
                </p>
              </li>
              <li>
                <p>
                  להשתמש באתר בדרך בלתי חוקית לרבות לצורך הפצה, שיווק או פרסום של חומר מזיק, מפיץ דיבה העלול לפגוע
                  ברגשות אחרים או בשמם הטוב.
                </p>
              </li>
              <li>
                <p>
                  להפריע לשימושם של אחרים או החברה באתר.
                </p>
              </li>
              <li>
                <p>
                  לעשות כל שימוש באתר באופן שיש בו ניסיון להתערב באופן אלקטרוני או ידני בתכני האתר ובפעילותו לרבות בדרך
                  של העלאת קבצים המכילים מידע לא תקין או וירוסים למיניהם.
                </p>
              </li>
              <li>
                <p>
                  להשיג או לנסות להשיג גישה בלתי מורשית לאתר או לאיזו ממערכותיו.
                </p>
              </li>
              <li>
                <p>
                  להציב קישורים לאתר זה בלא קבלת רשות מראש ובכתב מהחברה.
                </p>
              </li>
              <li>
                <p>
                  החברה שומרת על זכותה, על פי שיקול דעתה הבלעדי ובכל עת למנוע או להגביל את שימוש המשתמש באתר, בין היתר
                  עקב הפרת אחד או יותר מתנאי השימוש הנ"ל, מבלי צורך במתן הודעה מראש, ומבלי שתחול על החברה כל אחריות כלפי
                  המשתמש וכלפי כל צד ג'.
                </p>
              </li>
            </ol>
          </li>


          <!-- 5-->
          <li>
            <h2>פרטיות</h2>
            <ol>
              <li>
                <p>
                  טלרון מחויבת להגן ולכבד את פרטיותך, כמשתמש, מתקשר, מבקר או המוסר מידע לחברה.
                </p>
              </li>
              <li>
                <p>
                      <span>
                        השימוש באתר כפוף ל
                      </span>
                  <router-link element="a" :to="{name:'privacy-policy'}">מדיניות הפרטיות של האתר</router-link>
                  <span>
המהווה חלק בלתי נפרד מתקנון זה. מבלי לגרוע מהאמור במדיניות הפרטיות של האתר. אנא קרא אותה בעיון.
                      </span>
                </p>
              </li>
            </ol>
          </li>


          <!-- 6-->
          <li>
            <h2>הגבלת אחריות</h2>
            <ol>
              <li>
                <p>
                  התכנים באתר ניתנים לשימוש כמות שהם (AS IS). למען הסר ספק, הרי שלא ניתן להתאימם לצרכיו האישיים של כל משתמש ומשתמש. לא תהיה למשתמש כל טענה, דרישה או תביעה כלפי החברה בגין תכונות של התכנים, יכולותיהם, מגבלותיהם או התאמתם לצרכיו.
                </p>
              </li>
              <li>
                <p>
                  החברה אינה מתחייבת ששירותי האתר לא יופרעו, ינתנו כסדרם או בלא הפסקות, יתקיימו בבטחה וללא טעויות, ויהיו חסינים מפני גישה בלתי-מורשית למחשבי החברה או מפני נזקים, קלקולים, תקלות או כשלים - והכול, בחומרה, בתוכנה, בקווי ובמערכות תקשורת, אצל החברה או אצל מי מספקיה.
                </p>
              </li>
              <li>
                <p>
                  הסעד הבלעדי והממצה שיהיה למשתמש במקרה של הפרה יסודית של התקשרות יהיה ביטול הזמנה, ולא תהיינה למשתמש טענות נוספות כנגד החברה.
                </p>
              </li>
              <li>
                <p>
                  נפלה טעות סופר ו/או קולמוס שהינה חריגה וברורה על פניה, בתיאורו של מוצר/שירות, כגון (אך לא רק) מחיר הנקוב באגורות במקום בשקלים, לא יחייב הדבר את החברה.
                </p>
              </li>
              <li>
                <p>
                  בשום מקרה החברה לא תהיה אחראית לכל נזק ישיר, עקיף, מיוחד, עונשי או תוצאתי מכל מין וסוג שהוא, כתוצאה מן הגישה אל האתר ו/או השימוש באתר או כתוצאה משימוש ו/או ביסוס ו/או הסתמכות על כל מידע המופיע באתר.
                </p>
              </li>
              <li>
                <p>
                  במסגרת האתר עשויה החברה לספק קישורים והפניות לאתרים ודפים חיצוניים לאתר במרשתת, המופעלים על ידי צדדים שלישיים (להלן: "אתרים חיצוניים"). ייתכן כי באתרים החיצוניים יתבקש משתמש ליתן פרטים לצורך הרשמה וכד'. אין לחברה כל ידיעה, שליטה או אחריות לגבי הנעשה באתרים חיצוניים אלו. מסירת פרטים והרשמה באתרים אלו אינה כפופה למדיניות הפרטיות של החברה אלא למדיניות הפרטיות של אותם אתרים חיצוניים ולהוראות כל דין. כל פעולה שיבצע המשתמש בקשר לאתרים חיצוניים אלו הינה באחריותם הבלעדית שלו ושל בעלי האתרים החיצוניים בלבד, ולמשתמש לא תהיה והוא מוותר מראש על כל טענה ו/או דרישה בגין אובדן, הפסד או נזק ישירים ו/או עקיפים כנגד החברה ו/או מי מטעמה הנובעים מהסתמכות ו/או שימוש בתכנים ו/או במידע הניתן באתרים אלו.
                </p>
              </li>
              <li>
                <p>
                  החברה רשאית לשנות מעת לעת את מבנה האתר, מראהו ועיצובו, כמו גם את את היקף וזמינות השירותים המוצעים, ותהיה רשאית לשנות כל היבט אחר הכרוך בו - והכל, בלי שתידרש להודיע למשתמש מראש. שינויים כאלה יבוצעו, בין השאר, בהתחשב באופי הדינאמי של האינטרנט ובשינויים הטכנולוגיים והאחרים המתרחשים בה. מטבעם, שינויים מסוג זה עלולים להיות כרוכים בתקלות ו/או לעורר בתחילה אי-נוחות וכיו"ב. לא תהיה לך כל טענה, תביעה ו/או דרישה כלפי בגין ביצוע שינויים כאמור ו/או תקלות שיתרחשו אגב ביצועם. מבלי שיש בכך כדי לגרוע מן האמור לעיל, החברה רשאית להפסיק בכל עת וע"פ שיקול דעתה הבלעדי את מתן השירותים המוצעים, כולם או מקצת
                </p>
              </li>
            </ol>
          </li>

          <!-- 7-->
          <li>
            <h2>שיפוי</h2>
            <ol>
              <li>
                <p>
                  המשתמש מתחייב לשפות את החברה ו/או מי מטעמה בגין כל נזק, הפסד, אבדן-רווח, תשלום או הוצאה שייגרמו להם עקב הפרת התקנון. כמו כן, מתחייב המשתמש לשפות את החברה ו/או מי מטעמה בגין כל טענה, תביעה ו/או דרישה שתועלה נגדם ע"י צד שלישי כלשהו כתוצאה מקישורים שביצעת לאתר החברה.

                </p>
              </li>
            </ol>
          </li>

          <!-- 8-->
          <li>
            <h2>קניין רוחני</h2>
            <ol>
              <li>
                <p>
                  כל זכויות היוצרים והקניין הרוחני של האתר לרבות שם האתר, עיצובו, תכניו, זכויות בבסיסי נתונים, זכויות יוצרים, סימני מסחר, קבצי גרפיקה, סודות מסחריים, זכויות קניין רוחני אחרות בחומר הנמצא באתר זה וכן קוד התוכנה, הם בבעלותנו או בבעלות מורשים מטעמינו, לרבות (ומבלי לגרוע): תכנים, שירותים המוצעים בו, עיצוב, ובכל תוכנה, יישום, קוד מחשב, קובץ גרפי, טקסט וכל חומר אחר הכלול באתר, בין בממשק החיצוני, בין בקוד מקור ובין בקוד יעד. אין להעתיק, לשנות, להתאים, לפרסם, לשדר, להפיץ, למכור או להעביר חומר כל שהוא באתר זה או את קוד התוכנה, בחלקו או בשלמותו, ללא אישור מראש ובכתב מהחברה. על אף האמור לעיל, תוכנו של אתר זה ניתן להורדה, להדפסה או להעתקה לשימושך האישי והלא מסחרי.
                </p>
              </li>
              <li>
                <p>
                  על המשתמש נאסר להכניס שינויים, להעתיק, לפרסם, להפיץ, לשדר, להציג, לבצע, לשכפל, להנפיק רישיון, ליצור עבודות נגזרות, לבצע הנדסת-היפוך (reverse engineering), או למכור באופן חלקי או מלא, זמני או קבוע, בכל צורה שהיא, כל חלק מן המידע והתכנים הכלולים באתר ללא הסכמה מראש ובכתב של החברה. כמו כן, אין לעשות שימוש באתר באופן המהווה או שעלול להוות הפרה או פגיעה בקניינה הרוחני של החברה, ללא הסכמתה המפורשת לכך בכתב ומראש.
                </p>
              </li>
            </ol>
          </li>

          <!-- 9-->
          <li>
            <h2>שירות לקוחות</h2>
            <ol>
              <li>
                <p>
                  לפרטים ובירורים באשר לפרסום באתר ניתן לפנות אל מחלקת שירות הלקוחות של טלרון:
                </p>
                <ul>
                  <li>
                    <span>בדואר אלקטרוני</span><a href="mailto:customer-cntr@talronbatt.co.il">customer-cntr@talronbatt.co.il</a>
                  </li>
                  <li>
                    <span>בטלפון</span><a href="tel:1700700130">1-700-700-130</a>
                  </li>
                </ul>
              </li>

            </ol>
          </li>

          <!-- 10-->
          <li>
            <h2>שונות</h2>
            <ol>
              <li>
                <p>
                  החברה שומרת לעצמה את הזכות להוסיף, לשנות, לגרוע ולהחליף בכל עת את התקנון, כולו או חלקו, לפי שיקול דעתה הבלעדי, וללא קבלת הסכמת המשתמש, בהודעה שתתפרסם באתר. נוסחו המחייב של התקנון הנו זה שיופיע מעת לעת באתר. באחריות המשתמש להתעדכן בשינויים שחלו, אם חלו, בתקנון. ויובהר, כי המשך שימוש באתר לאחר ביצוע שינוי כאמור יהווה ראיה לאישור המשתמש בדבר קבלת השינויים.
                </p>
              </li>
            </ol>
          </li>

          <!-- 11-->
          <li>
            <h2>תחום וסמכות שיפוט</h2>
            <ol>
              <li>
                <p>
                  על תקנון זה והשימוש באתר יחולו אך ורק דיני מדינת ישראל. מקום השיפוט הבלעדי בגין כל דבר ועניין הנובע מהאמור בתקנון ו/או מהשימוש באתר, הוא בבתי המשפט המוסמכים במחוז תל אביב בלבד.
                </p>
              </li>
            </ol>
          </li>

        </ol>




        <strong>ט.ל.ח</strong>
      </div>
    </layout-solid-blob>

  </div>
</template>


<script>
import asyncOperations from '@/client/extensions/composition/asyncOperations'
import { useStore } from 'vuex'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n/index'

export default {
  layout    : 'default',
  middleware: {},
  setup (props, context) {
    let { meta } = useMeta({ title: useI18n().t('talron.tos.pageTitle') }).meta

    return {
      meta
    }
  },
  data () {
    return {
      temp          : [],
      contactMethods: [],
    }
  },
  components: {},
  computed  : {},
  methods   : {},
  mounted () {

  },

  watch: {},

}
</script>

<style lang="scss" scoped></style>




