import { createRouter, createWebHashHistory, createMemoryHistory, createWebHistory } from 'vue-router'
import saffronMiddlewareBus from '@/client/router/saffronMiddlewareBus.js';
import asyncFactory from '@/client/extensions/modules/asyncComponentFactory.js'


let appName     = process.env.VUE_APP_APPLICATION_NAME;
let routeGroups = {};
let middleware  = {};
let finalRoutes = [];


let populateDeclaredRoutes = () => {

  // load all routes from modules
  let context = require.context('@/client/router/routes', true, /\.js/);
  context.keys().forEach(key => {
    // name is the routes file name without extension
    let name = key.split('/').pop().replace('.js', '');

    routeGroups[name] = context(key).default
  });

  // load all routes from app. route modules with same name as core will override
  context = require.context('@/client/applications/', true, /^\.\/.*\/router\/routes.*\.js$/);
  context.keys().forEach(key => {
    // filter only the modules for out application
    if (! key.startsWith('./'+appName)) {
      return;
    }
    // name is the routes file name without extension
    let name = key.split('/').pop().replace('.js', '');

    routeGroups[name] = context(key).default
  });

  // route overrides
  context = require.context('@/', true, /\/overrides\/client\/router\/routes\/.*\.js/);
  context.keys().forEach(key => {

    // name is the routes file name without extension
    let name = key.split('/').pop().replace('.js', '');

    routeGroups[name] = context(key).default
  });

  // todo: route app overrides - not tested
  context = require.context('@/', true, /overrides\/client\/applications\/.*\/router\/routes.*\.js$/);

  context.keys().forEach(key => {
    // filter only the modules for out application
    if ( ! key.startsWith('./overrides/client/applications/'+appName)) {
      return;
    }

    // name is the routes file name without extension
    let name = key.split('/').pop().replace('.js', '');

    routeGroups[name] = context(key).default
  });

  // merge the routes
  for ( const [key, routesArray] of Object.entries(routeGroups)) {
    finalRoutes = finalRoutes.concat(routesArray)
  }
};

let populateMiddleware = () => {
  let context;
  // middleware - from core
  context = require.context('@/client/router/middleware', true, /\.js/);
  context.keys().forEach(key => {
    // name is the routes file name without extension
    let name = key.split('/').pop().replace('.js', '');

    middleware[name] = context(key).default
  });

  // middleware - from override
  context = require.context('@/', true, /\/overrides\/client\/router\/middleware\/.*\.js/);

  context.keys().forEach(key => {
    // name is the routes file name without extension
    let name = key.split('/').pop().replace('.js', '');
    middleware[name] = context(key).default
  });

  // middleware - from app
  context = require.context('@/client/applications/', true, /^\.\/.*\/router\/middleware.*\.js$/);
  context.keys().forEach(key => {
    // filter only the modules for out application
    if ( ! key.startsWith('./'+appName)) {
      return;
    }

    let name = key.split('/').pop().replace('.js', '');

    middleware[name] = context(key).default

  });

  // middleware - from app override
  context = require.context('@/', true, /overrides\/client\/applications\/.*\/router\/middleware.*\.js$/);

  context.keys().forEach(key => {
    // filter only the modules for out application
    if ( ! key.startsWith('./overrides/client/applications/'+appName)) {
      return;
    }

    let name = key.split('/').pop().replace('.js', '');

    middleware[name] = context(key).default
  });
};

// TODO: decide on standard for a 404 page, and general error page
let populateAutomatedRoutes = () => {
  let routes = {};
  let context;

  let buildRouteDefinitionFromFileName = (key, componentPathPrefix, pathPrefix = './') => {
    let getPath = (key) => {
      let arrParts = key.replace('./', '').replace('.vue', '').split('/');
      let path = [];


      arrParts.forEach ((part, index) => {
        let cleanedPart = part;
        let isLastPart = index === (arrParts.length - 1);

        // this path part means the component inside it should have the path up until it, not including "index" in the end
        if (part === 'index' && isLastPart) {
          return true;
        }

        // this part represents an optional param
        if (part.startsWith('[') && part.endsWith(']') && isLastPart) {
          path.push(':' + part.substring(1, part.length-1) + '?');
          return true;
        }

        // this part represents a required param
        if (part.startsWith('[') && part.endsWith(']') && ! isLastPart) {
          path.push(':' + part.substring(1, part.length-1) + '?');
          return true;
        }

        // this part represents a "regular" path part
        path.push(cleanedPart);
      });

      return '/' + path.join('/');
    }

    let getName = (key) => {
      let arrParts = key.replace('./', '').replace('.vue', '').split('/');
      let path = [];

      let strName;

      // noinspection DuplicatedCode -
      arrParts.forEach((part, index) => {
        let isLastPart = index === (arrParts.length - 1);
        let cleanedPart = part;

        // path ending in an index.vue should not have the "index" apended to their name
        if (part === 'index' && isLastPart) {
          return true;
        }

        if (part.startsWith('[') && part.endsWith(']')) {
          cleanedPart = part.substring(1, part.length-1);
        }
        path.push(cleanedPart);
      });


      if (path.length !== 0) {
        strName = path.join('-');
      } else {
        strName = 'index';
      }

      return strName;
    }

    let isIgnore = (key) => {
      let isIgnore = false;
      let arrParts = key.replace('./', '').replace('.vue', '').split('/');
      let path = [];


      arrParts.forEach ((part, index) => {
        let cleanedPart = part;
        let isLastPart = index === (arrParts.length - 1);

        // skip "index" parts
        if (part === 'index' && isLastPart) {
          return true;
        }

        // skip params parts
        if (part.startsWith('[') && part.endsWith(']')) {
          return true;
        }

        // this part represents a "regular" path part - do check
        if (cleanedPart.startsWith('_')) {
          isIgnore = true;
          return false;
        }
      });

      return isIgnore;
    };

    let cleanKey = key.replace(pathPrefix, '');

    let result =  {
      path: getPath(cleanKey),
      name: getName(cleanKey),
      component: asyncFactory(componentPathPrefix + '/' + cleanKey),
      meta : {'isPage': true,},
      props: true,
      isIgnore : isIgnore(key),
    }

    return result;
  };


  // core pages
  context = require.context('@/', true, /\/client\/pages\/.*\.vue/);
  context.keys().forEach(key => {
    if ( ! key.startsWith('./client/pages')) {
      return true;
    }

    let definition = buildRouteDefinitionFromFileName(key, 'pages', './client/pages/');

    routes[definition.name] = definition;
  });

  // core pages  overrides
  context = require.context('@/', true, /\/overrides\/client\/pages\/.*\.vue/);
  context.keys().forEach(key => {
    let definition = buildRouteDefinitionFromFileName(key, 'pages', './overrides/client/pages/');
    routes[definition.name] = definition;
  });

  // app pages
  context = require.context('@/client/applications/', true, /^\.\/.*\/pages.*\.vue$/);
  context.keys().forEach(key => {

    // filter only the modules for out application
    if (! key.startsWith('./'+appName)) {
      return;
    }

    // name is the routes file name without extension
    let definition = buildRouteDefinitionFromFileName(key, 'applications/'+appName+'/pages', './'+appName+'/pages/');

    routes[definition.name] = definition;
  });

  // app pages overrides
  context = require.context('@/', true, /overrides\/client\/applications\/.*\/pages\/.*\.vue$/);
  context.keys().forEach(key => {

    // filter only the modules for out application
    if ( ! key.startsWith('./overrides/client/applications/'+appName)) {
      return;
    }

    // name is the routes file name without extension
    let definition = buildRouteDefinitionFromFileName(key, 'applications/'+appName+'/pages', './overrides/client/applications/'+appName+'/pages/');

    routes[definition.name] = definition;
  });

  // push into the final routes array. notice
  for (const [name, conf] of Object.entries(routes)) {
    if (conf.isIgnore) {
        continue;
    }

    finalRoutes.push(conf);
  }
};



let getHistory = () => {
  let history;

  if (utilities.isSSR()) {
    history = createMemoryHistory();
  }

  if ( ! utilities.isSSR()&& config.useSSR) {
    history = createWebHistory();
  }

  if (! utilities.isSSR()&& ! config.useSSR ) {
    history = createWebHashHistory();
  }

  return history;
};


populateDeclaredRoutes();
populateMiddleware();
populateAutomatedRoutes();

let routerFactory = (app) => {
  const router =  createRouter({
    history: getHistory(),
    routes : finalRoutes,
    linkActiveClass: config.router.linkActiveClass,
    scrollBehavior (to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return { top: 0 }
      }
    }
  });
  router['app']        = app;
  router['isStoreSet'] = false;

  // set global middleware
  for (const [name, factory] of Object.entries(middleware)) {
      let middleware = factory(router);
      if (middleware.routerMethod !== 'saffronBus') {
          router[middleware.routerMethod](middleware.handler);
      }
  }

  // saffron middleware bus, allows defining middle ware on components with the middleware option
  router.beforeResolve(saffronMiddlewareBus(router, middleware));

  return router;
};

export default routerFactory;
