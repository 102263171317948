<template>
    <battery-view v-bind="$props"></battery-view>
</template>



<script>

import {useStore, mapGetters} from 'vuex';
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import BatteryView from '@/client/applications/talron/views/Battery.vue';

 
export default {
    layout    : 'default',
    middleware: {
        requireBattery : {}
    },

    props: {
        displayMode: {
            type: String,
            default: 'default',
        }
    },
    setup(props) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        let {meta}                                 = useMeta({title: useI18n().t('talron.battery.browserTitle')}).meta
        return {asyncOps, asyncOpsReady, asyncStatus,meta};
    },

    data() {
        return {}
    },
    components: {BatteryView},
    computed  : {
        ...mapGetters({
              'isInWarranty'  : 'talron/isBatteryWarrantyValid',
          }),
    },
    methods   : {},
    mounted () {
        if ( ! this.isInWarranty) {
            this.$store.commit('talron/appActionState', 'error');
        }
    },
    unmounted () {
        this.$store.commit('talron/appActionState', 'default');
    },
}
</script>

<style lang="scss" scoped>
.content {
    position: relative;
}

.battery-image-wrap {
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: 1200px) {
        top: -120px;
    }

    img {
        height: 86px;
        width: auto;
    }
}

.warranty-title {
    text-align: center;
    margin-top: 15px;

    @media (min-width: 1200px) {
        margin-top: 0px;
    }

    .title-text {

    }

    .warranty-end-date {
        font-size: 26px;
        font-weight: bold;

        @media (min-width: 1200px) {
            margin-top: 5px;
        }
    }
}

.progress-bar-wrapper {
    margin: 15px auto 15px;

    @media (min-width: 1200px) {
        margin-top: 10px;
    }
}

.title-separator-wrap {
    margin: 40px auto 20px auto;

    @media (min-width: 1200px) {
        margin-top: 10px;
    }
}

.desktop-battery-details {
    margin-top: 30px;
    margin-bottom: 5px;
}

.details-title {
    cursor: pointer;
    padding: 10px 18px;
}

.detail {
    padding: 10px 18px;

    &:nth-child(odd) {
        background-color: var(--global-muted-background);
    }

}

</style>

<style lang="scss">

.title-separator .content-text {
   // color: var(--global-background)!important;
}
</style>




