import {nextTick, computed } from 'vue';

/**
 * When a route meta has requiresAdmin, will redirect guests based on store/auth module
 * Will hang and wait, if the auth status is not determined.
 *
 * @param router
 * @returns {{handler: handler, routerMethod: string}}
 */
export default (router) => {
    let isAuthWorking = computed(() => {
        return router.$store.getters['user/isFetchingProfile'] ||
               router.$store.getters['user/isFetchingJwt']
    });

    return {
        routerMethod: 'afterEach',
        handler: async (to, from) => {
           if ( ! window){
               return;
           }
           // wait for page to load
           await utilities.wait(300);
    
            nextTick(() => {
               
                const el = document.querySelector('h1');
                console.log('focusing', el);
                if (el) {
                    el.focus();
                    console.log('did focus');
                }
                
            });
          
           

        }
    }
}
