<template>
    <div>
        <div class="uk-container uk-container-small">

            <h2 class="uk-margin-xlarge-top">
                אזור בדיקות
            </h2>
            <p>
                יש ללחוץ על כפתור הסריקה. במידה ועובדים ממחשב שולחני, ניתן להקטין את החלון, והכפתור יופיע כמו בנייד.
                <br><br>
                יש לצלם את הברקוד עצמו בלבד, עם כמה שפחות מהמדבקה. נקודה זו היא קריטית ביותר
                <br><br>
                במידה והניסוי יצליח, ניתן למשתמש הסבר ברור טרם הסריקה, לסרוק רק את הברקוד עצמו בלי הרבה רקע.
                <br><br>
                חשוב שהתמונה תהיה בפוקוס.
                <br><br>

            </p>
            <div class="uk-margin-large-top uk-margin-medium-bottom">
<!-- TODO: this needs to be a "capture" img input only -->
                <form-input

                    label="core.test"
                    type="TalronBatteryBarcodeStills"
                    v-model="testValue" />
            </div>
            <div>
                <strong>Val: </strong> <span>{{testValue}}</span>
            </div>

        </div>


    </div>
</template>

<script>
export default {
    data: function () {
        return {
            testValue: 'NOT SCANNED'
        };
    },
    computed: {

    }


}
</script>

<style scoped lang="scss">

</style>
