<template>
    <div class="page talron main-page scan-page">

        <layout-solid-blob title="talron.privacyPolicy.viewTitle" >
          <div class="ac-doc">
            <p>בגלישתך באתר זה (להלן:"האתר") היכן מקבל ומסכים לתנאי מדיניות הפרטיות.</p>
            <p>מדיניות הפרטיות מתייחסת לסוג המידע שאתה תספק לנו או מידע אשר אנו נלמד אודותיו
משימושך באתר. האתר מנוהל ומופעל ע"י חברת טלרון מצברים בע"מ (להלן: "החברה"),
יבואנית מבצרים ושמנים לתחום הרכב ותחום התעשייה.</p>
            <ol class="uk-list uk-padding-right">
              <li>
                <h2>הפרטיות שלך</h2>
                <p>הפרטיות שלכם חשובה לנו מאוד. אנו מנהלים את האתר בסטנדרטים הגבוהים ביותר
של אבטחת מידע והגנה על מידע אישי. אנו פועלים בהתאם להוראות חוק הגנת
הפרטיות, התשמ"א 1981 ומחויבים להגן על פרטיותך ועל שמירת פרטי הזיהוי האישיים
שלך.</p>
                <p>אנו אוספים מידע אישי אודותיך רק כאשר אתה תספק לנו אותו באופן ספציפי וביודעין.
אנו מאמינים, כי אתה צריך לדעת בדיוק איזה מידע אישי אנו אוספים וכיצד אנו מתכוונים
להשתמש בו בטרם תחלוק אותו עימנו. אנו עשויים לבקש ממך מידע אישי כגון שם,
מספר טלפון וכתובת. אנו עושים זאת רק כאשר אתה מבקש מידע או מבקש להירשם
לשירותים הטעונים הרשמה או מנוי.</p>
                <p>כאשר אנו מתכוונים להשתמש במידע האישי שלך, אנו מבקשים זאת מראש , על מנת
שתחליט אם אתה רוצה לספק לנו את המידע או לא.</p>
                <p>מידע המאפשר זיהוי אישי שלך, יישמר בסודיות מוחלטת. פרטי זיהוי אישיים שאתה
מספק לנו לא יהיו זמינים לצדדים שלישיים ללא אישור מפורש שלך.</p>
              </li>
              <li>
                <h2>פרטי זיהוי אישיים</h2>
                <p>"פרטי זיהוי אישים" הינו מידע שניתן להשתמש בו כדי לזהות אותך באופן מפורש ו/או
ניתן באמצעותו ליצור עמך קשר. מידע מסוג זה יכול לכלול את שמך האמיתי, כתובת
דואר אלקטרוני, כתובת דואר, מספר טלפון וכדומה, אשר אינם זמינים לציבור באופן
אחר.</p>
                <p>באפשרותך להשתמש במגוון רחב מהתכונות של האתר ללא צורך בחשיפת פרטי הזיהוי
האישיים שלך.יחד עם זאת, ייתכנו מקרים, בהם ייתכן ותידרש למסור מידע אישי על
מנת לאפשר למנהלי האתר לספק לך מידע, נתונים, או את השירותים שביקשת. לכן,
מדיניות הפרטיות שלהן מפרטת כיצד מנהלי האתר יטפלו במידע אישי שלך שנאסף או
התקבל על ידי או באמצעות האתר.</p>
                <p>מדיניות הפרטיות שלהלן חלה אך ורק על אתר זה. מדיניות הפרטיות אינה חלה על
שיטות הבעודה של חברות או גופים אשר אינם בבעלות או בשליטת מנהלי האתר.</p>
              </li>
              <li>
                <h2>איסוף ושימוש במידע אישי</h2>
                <p>אנו מגבילים את בקשותינו לקבלת מידע אישי בסיסי בלב,ד כגון:</p>
                <ul>
                  <li><span>שם</span></li>
                  <li><span>מספר טלפון</span></li>
                  <li><span>כתובת דואר</span></li>
                  <li><span>כתובת דואר אלקטרוני</span></li>
                  <li><span>מיקום גאוגרפי: באמצעות איתור טלפון סלולארי</span></li>
                </ul>
                <p>אנו מגבילים את איסוף המידע האישי למידע שאנו מאמינים כמינימלי בי ותר על מנת
לספק לך את השירותים המוצעים באתר או באמצעותו.</p>
                <p>עיקר השימוש שלנו במידע האישי הינו שלוש מטרות: למלא אחר בקשותיך לקבלת
שירותים מסוימים , כדי ליצור עמך קשר כאשר אתה מבקש זאת , ועל מנת להודיע לך על
הצעות מיוחדות , מבצעים עדכניים ושירותים חדשים אחרים. האתר עושה שימוש בתקני
הגנת אבטחה ונתונים הקיימים בתעשייה , על מנת להבטיח את ההגנה על הפרטיות
שלך.</p>
              </li>
              <li>
                <h2>קבלה</h2>
                <p>אם וככל שתמסור מידע אישי מכל סוג שהוא , מידע זה יישמר במאגרי המידע הרשומים
כחוק של החברה ולצורך ניהול וייעול השירות והקשר עימך , לצרכים שיווקיים /
תפעוליים , או סטטיסטיים , לרבות עיבוד המידע ודיוור ישיר לצורך מימוש מטרות אלו וכן
למתן שירות מסור יותר תוך עידוד נאמנות לקוחות החברה . דע לך כי אינך נדרש על-פי
חוק למסור מידע לחברה , וכי העברת מידע על ידך לחברה נעשית על אחריותך בלב,ד
מרצונך ובהסכמתך , ולא תהיה לך כל טענה ו/או בתיעה כלפי החברה ונציגיה בנושא.</p>
                <p>כמו כן , ככל שתמסור או תעלה כל מידע אישי לחברה או לאתר , הינך מצהיר בזאת כי
המידע שתמסור ו/או תעדכן באתר , הנו נכון אמין ומדויק וכי הנך מוסר את המידע בשמך
ועבור עצמך בלב,ד או שהורשת כדין למסור מידע זה עבור אחר , ובכל מקרה תהא
אחראי בלעדי למסירת מידע זה.</p>
                <p>אנחנו לא נמכור או נשכיר את פרטי הזיהוי האישיים שלך לאף אחד. אנו עשויים לשלוח
מידע המאפשר זיהוי אישי שלך לחברות או אנשים אחרים , רק כאשר:</p>
                <ul>
                  <li><span>יש לנו את הסכמתך לחלוק את המידע</span></li>
                  <li><span>כאשר נצטרך לחלוק את המידע האישי שלך על מנת לספק את המוצר או
השירות המבוקש על ידך</span></li>
                  <li><span>על פי דרישה שנקבל במסגרת , צווי בית משפט או הליך משפטי</span></li>
                  <li><span>כאשר נמצא כי פעולות שבוצעו על ידך באתר , יש בהן כדי להפר את כל הכללים
המקומיים או החקיקה הרלוונטית לבגי התנהגות באינטרנט .</span></li>
                  <li><span>לשם עריכת ניתוחים סטטיסטיים והבערת מידע סטטיסטי או אחר לצדדים
שלישיים. מידע כאמור לא יאפשר זיהוי אישי של ך.</span></li>
                  <li><span>בכל מקרה בו תסבור החברה כי מסירת המידע נחוצה על מנת למנוע נזק חמור
לרכושו ו/או לגופו של אדם , או על מנת למנוע נזק חמור אחר לפי שיקול דעתה
הבלעדי.</span></li>
                  <li><span>במקרה בו החברה תימכר , תבעיר ו/או תמזג את פעילותה עם תאגיד אחר ,
ובלבד שמקבל המידע החדש , יקבל על עצמו את הוראות תנאי שימוש אלו
במלואן .</span></li>
                </ul>
                <p>על-פי חוק הגנת הפרטיות , התשמ"א 1981- , היכן , או מי מטעמך מיופה כוחך , רשאי
לעיין במידע אודותיך אשר מצוי במאגר החברה ואף לבקש מאיתנו לתקן מידע זה אם
אינו נכון , שלם או מדויק ואף למחוק אותו במקרים מתאימים . כדי לממש זכות זו , יש
לשלוח בקשה אל החברה באמצעות דף יצירת הקשר באתר או באמצעות אחד מפרטי
הקשר המפורטים בתחתית מסמך זה.</p>
              </li>
              <li>
                <h2>COOKIES (עוגיות)</h2>
                <p>קובץ COOKIE הוא קובץ המכיל חלקים מסוימים של מידע שהאתר יוצר בעת ביקור 
באתר . הוא יכול לעקוב ולבדוק איך ומתי אתה משתמש באתר , באיזה אתר ביקרת מיד
לפני ביקורך באתר , והוא יכול לאחסן את המידע הזה עליך.</p>
                <p>ישנם שני סוגים של קבצי COOKIE, "זמני" ן-"תמידי". COOKIE זמניים אוגרים מידע רק 
על משך הזמן שאתה מחובר לאתר- הם לא נשמרים על גבי הכונן הקשיח . ברגע שאתה 
עוזב את האתר, מחולל ה-COOKIE אינו שומר עוד את המידע שצבר.</p>
                <p>על מנת לשפר את חווית הגלישה שלך באתר, האתר משתמש ב"COOKIES זמניים". רוב 
הדפדפנים כיום יודעים לזהות מתי "COOKIE" מוצע, ולאפשר לך לסרב או לקבל אותו. 
אם אינך בטוח אם לדפדפן שלך יש יכולת זו , עליך לבדוק עם יצרן התוכנה או ספק
שירותי האינטרנט שלך. אין אנו משתמשים בקבצי COOKIE כדי לעקוב אחר הרגלי 
הניווט של המבקרים שלנו .</p>
              </li>
              <li>
                <h2>גישתך ושליטתך בפרטי הזיהוי האישים</h2>
                <p>באפשרותך לבצע את הפעולות הבאות , בכל עת , על ידי פנייה אלינו באמצעות כתובת
הדוא"ל שלהלן :</p>
                <ul>
                  <li><span>לבדוק איזה מידע אישי שלך יש בידינו , אם בכלל.</span></li>
                  <li><span>לשנות\לתקן את פרטי הזיהוי האישיים שיש בדינו עליך.</span></li>
                  <li><span>לבקש מאיתנו למחוק כל מידע אישי שיש לנו אודותיך (יחד עם זאת יש לדעת כי ,
לא נוכל לספק שירותים מסוימים ללא המידע האישי)</span></li>
                  <li><span>להביע כל חשש שיש בידך לגבי השימוש שנעשה במידע האישי שלך</span></li>
                </ul>
              </li>
              <li>
                <h2>דואר אלקטרוני</h2>
                <p>כאשר אתה שולח אלינו דואר אלקטרוני אנו נעשה שימוש בשמך ובכתובת הדואר
האלקטרוני שלך רק כדי לענות על החששות שאתה מעלה בפנינו . אנו עשויים לשתף
אנשים מסוימים במסגרת הארגון שלנו כדי שיסייעו לנו לענות לחששותיך.</p>
              </li>
              <li>
                <h2>הסרה מרשימת התפוצה</h2>
                <p>אם משתמש אינו מעוניין שמידע אישי שלו ייאסף , יימסר או ייחשף באופן כלשהו כמתואר
לעיל , עליו להימנע מהעברת פרטיו האישיים לחברה כאמור או להסיר את עצמו מרשימת
התפוצה/מהמאגר באמצעות <a href="mailto:customer-cntr@talronbatt.co.il">customer-cntr@talronbatt.co.il</a> </p>
              </li>
              <li>
                <h2>שינויים במדיניות הפרטיות שלנו</h2>
              </li>
            </ol>
          </div>
        </layout-solid-blob>

    </div>
</template>



<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import {useStore} from "vuex";
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
export default {
    layout    : 'default',
    middleware: {

    },
    setup (props, context) {
        let {meta}                                 = useMeta({title: useI18n().t('talron.privacyPolicy.pageTitle')}).meta

        return {
          meta
        }
    },
    data() {
        return {
            temp : [],
            contactMethods: [

            ],
        }
    },
    components: {

    },
    computed  : {


    },
    methods   : {

    },
    mounted() {

    },

    watch: {},

}
</script>

<style lang="scss" scoped></style>




