<template>
    <div class="page talron uk-animation-fade replace-external-page" >
        <layout-process :title="translate('talron.replace.external.processTitle')" v-show=" ! processFinished">

            <div class="process-steps">
                <div class="process-step">
                    <span class="process-step__icon">
                        <img :src="getDynamicAssetUrl('images/icons/replace-battery-crossed.svg')"
                             alt=""/>
                    </span>
                    <h2 class="process-step__title tr-font-bold tr-font-m uk-margin-remove-bottom">{{translate('talron.replace.external.badBatteryTitle')}}</h2>
                    <span class="process-step__content-item process-step__content-item--strong">
                        <span> {{translate('talron.replace.external.batterySerialNumberLabel')}} : </span><span>{{batteryInfo.serialNumber}}</span>
                    </span>
                    <span class="process-step__content-item">
                        <span> {{translate('talron.replace.external.badBatteryInWarantyUntil')}} : </span><span>{{batteryInfo.warranty.toDate}}</span>
                    </span>
                </div>
                <div class="process-step__separator">
                    <span class="process-step__separator-icon" uk-icon="long-arrow-left"></span>
                </div>
                <div class="process-step">
                    <span class="process-step__icon">
                        <img :src="getDynamicAssetUrl('images/icons/replace-battery.svg')"
                             alt=""/>
                    </span>
                    <h2 class="process-step__title tr-font-bold tr-font-m uk-margin-remove-bottom">{{translate('talron.replace.external.newBatteryTitle')}}</h2>
                    <span class="process-step__content-item">
                        <span> {{translate('talron.replace.external.newBatteryExplain')}}</span>
                    </span>
                    <div class="process-step__actions">
                        <form-button @click="requestExternalBatteryReplacement">{{translate('talron.replace.external.replaceConfirm')}}</form-button>
                        <a class="process-step__cancel" href="#" @click.prevent="$router.push({name: 'battery'});">{{translate('talron.replace.external.cancel')}}</a>
                    </div>
                </div>
            </div>

            <div class="desktop-cancel-link uk-visible@l">
                <a href="#" @click.prevent="$router.push({name: 'battery'});">
                    {{translate('talron.general.cancelActionLinkDesktop')}}
                </a>
            </div>

        </layout-process>

        <layout-blob class="uk-animation-slide-bottom-small" v-show="processFinished">

            <div class="success-header">
                <div class="success-icon-wrapper">
                    <span class="success-icon" uk-icon="check-circle"></span>
                </div>
           <h1 aria-live="assertive" tabindex="0"
                    class="replace-success-title">{{ translate('talron.replace.external.successTitle') }}</h1>
            </div>

            <div class="success-content">
                <ul class="success-list">
                    <li class="success-list-item uk-flex uk-flex-top uk-flex-between uk-flex-gap">
                        <span class="success-list-item__number">1</span>
                        <span class="success-list-item__content">
                            {{ translate('talron.replace.external.successItem1')}}
                        </span>
                    </li>

                    <li class="success-list-item uk-flex uk-flex-top uk-flex-between uk-flex-gap">
                        <span class="success-list-item__number">2</span>
                        <span class="success-list-item__content">
                            {{ translate('talron.replace.external.successItem2')}}
                        </span>
                    </li>

                    <li class="success-list-item uk-flex uk-flex-top uk-flex-between uk-flex-gap">
                        <span class="success-list-item__number">3</span>
                        <span class="success-list-item__content">
                            {{ translate('talron.replace.external.successItem3', {batterySerialNumber: batteryInfo.serialNumber})}}
                        </span>
                    </li>
                </ul>
            </div>

            <button-group class="back-button-gruop uk-flex uk-flex-center">
                <form-button @click.prevent="$router.push({name: 'index'})">{{translate('talron.replace.external.backHomeButtonCta')}}</form-button>
            </button-group>

        </layout-blob>

    </div>
</template>



<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import {useStore, mapGetters} from "vuex";
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
export default {
    layout    : 'default',
    middleware: {
        requireTalronUser: {},
        requireBattery : {}
    },
    setup (props, context) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        let {meta}                                 = useMeta({title: useI18n().t('talron.replace.external.browserTitle')}).meta

        return {
            asyncOps, asyncOpsReady, asyncStatus,meta
        }
    },
    data() {
        return {
            processFinished: false,
            asyncData: {

            },
        }
    },
    components: [

    ],
    computed  : {
        ...mapGetters({
                          'userName'      : 'talron/userName',
                          'userId'        : 'talron/userId',
                          'userPersonalId': 'talron/userPersonalId',
                          'batteryInfo'   : 'talron/battery'
                      }),

    },
    methods   : {
        async requestExternalBatteryReplacement() {

            // show spinner
            this.$store.commit('ui/showGlobalSpinner', this.translate('talron.general.loadingSpinnerText'));

            let result = await this.asyncOps.asyncCall(
                'battery/replace/external',
                {
                    userId: this.userId,
                    batteryBarcode: this.batteryInfo.serialNumber
                },
                {method: 'patch'});
            // call API
            this.$store.commit('ui/hideGlobalSpinner');

            if (result.isError || result.data.status === "0" || result.data.status === 0 ||  result.data.status === false) {
                let errorText =  typeof result.data.rejectionReason === 'string' ? result.data.rejectionReason : this.translate('talron.general.actionFailed');
                return this.$s.ui.notification(errorText, 'error');
            }

            // the action is successful
            this.processFinished = true;
            this.$store.commit('talron/appActionState', 'success');

        }
    },
    mounted() {
        this.$store.commit('talron/appActionState', 'default');
    },
    unmounted() {
        this.$store.commit('talron/appActionState', 'default');
        this.$store.commit('talron/clearReplacementGUID', 'default');

        this.processFinished = false; // just to be sure in case component is kept alive
    }
}
</script>

<style lang="scss" scoped>

.process-steps {
    display: flex;
    gap: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  height: 100%;
  width: 100%;

  @media (min-width: 1200px) {
    flex-direction: row;
    gap: 40px;
  }
}

.process-step {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    &__separator {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      margin-top: 40px;
      margin-bottom: 40px;

      @media (min-width: 1200px) {
        width: auto;
        height: 400px;
        margin: 0;
      }

        &::after, &::before {
            content: ' ';
            position: absolute;
            height: 1px;
            width: calc(50% - 20px);
            top: 50%;
            background-color: #dddddd;

            @media (min-width: 1200px) {
                width: 1px;
                height: 120px;
                inset-inline-start: calc(50% - 2px);
            }
        }

        &::before {
            inset-inline-start: 0;

            @media (min-width: 1200px) {
                top: calc(50% - 140px);
                inset-inline-start: calc(50% - 2px);
                bottom: auto;
                width: 1px;
            }
        }

        &::after {
            inset-inline-end: 0;
            @media (min-width: 1200px) {
                top: auto;
                bottom: calc(50% - 140px);
                inset-inline-end: auto;
            }
        }
    }

    &__separator-icon {
        margin-top: 10px;
        margin-bottom: 10px;
        transform: rotate(-90deg);
        color: var(--global-primary-background-dark);
      display: block;
      z-index: 4;

        @media (min-width: 1200px) {
            transform: none;
            width: 45px;
            height: 16px;
        }
    }

    &__icon {
        display: inline-block;
        width: 34px;
        height: 34px;
        flex: 34px;
        height: auto;

        img {
            width: 34px;
            height: auto;
            object-fit: contain;
        }
    }

    &__title {
        margin-top: -5px;
        font-weight: bold;
        color: var(--global-primary-background-dark);
    }

    &__content-item {
        text-align: center;

        &--strong {
            font-weight: 500;
        }
    }

    &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 15px;

    }

    &__cancel {
      margin-top: 16px;
        font-weight: bold;
        font-size: var(--font-s);
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

.desktop-cancel-link {
    margin-top: 50px;
    text-align: center;

    a {
        color: var(--global-link-color);
        text-decoration: underline;
        font-weight: 700;

        &:hover {
            text-decoration: none;

        }
    }
}

.success-header {
    color: var(--global-success-background);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.success-icon-wrapper{

}

.success-icon {

}

.replace-success-title {
    font-size: var(--font-l);
    font-weight: bold;
    color: var(--global-success-colors);
    margin-top: 12px;
    margin-bottom: 30px;
}

.success-content {
    counter-reset: test;

    .success-list {
        list-style: none;
        padding: 0;
        margin: 0;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        padding: 25px 0;
    }

    .success-list-item {
        margin-bottom: 40px;

        &:last-of-type {
            margin-bottom: 0;
        }

        &__number {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 26px;
            width: 26px;
            flex: 0 0 26px;
            border: 2px solid var(--global-color);
            border-radius: 50%;
            text-align: center;
            font-weight: bold;
            font-size: 14px;
            line-height: 0px;
            color: var(--global-color);
        }

        &__content {
            display: inline-block;
            text-align: justify;
            flex: 100%;
        }
    }
}

.back-button-gruop {
    margin-top: 20px;
}
</style>

<style lang="scss">


</style>



