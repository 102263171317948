<template>

    <layout-default v-show="finalDisplayMode === 'login'">
        <div class="page talron login-page main-page uk-animation-fade uk-animation-fast" >
            <layout-welcome
                :topBlockColorDesktop="''"
                :topBlockColorMobile="''"
                :topBlockBackgroundUrlMobile="getDynamicAssetUrl('images/backgrounds/mobile-login-background.svg')"
                :topBlockBackgroundUrlDekstop="getDynamicAssetUrl('images/backgrounds/scan-bg-desktop.webp')"
                :bottomBlockColorDesktop="'var(--global-background)'"
                :bottomBlockColorMobile="'var(--global-background)'"
                :bottomBlockBackgroundUrlDesktop="''"
                :bottomBlockBackgroundUrlMobile="''"
            >
                <template #topBlock>
                      <h1 tabindex="0"
                          v-if="finalDisplayMode === 'login'"
                        aria-live="assertive"
                        class="welcome-title">{{ translate('talron.login.viewTitle') }}</h1>

                    <div class="login-explain">
                        <span>{{ translate('talron.login.formExplain') }}</span>
                    </div>

                    <base-form
                        ref="loginStep1Form"
                        class="intro-form"
                        method="post"
                        action="operator/verify-personal-id/stage-1"
                        v-model="loginFormData"
                        @form:submitSuccess="handlePersonalIdVerified"
                        :config="formConfig"></base-form>

                    <div class="help-link-wrapper">
                        <a @click.prevent="$refs.helpModal.showModal()"
                           href="#"
                           style="color: var(--global-secondary-background)"
                           class="help-link">
                            {{ translate('talron.login.cantLoginLink') }}
                        </a>
                    </div>
                </template>
                <template #bottomBlock>
                    <div class="info-block-inner">
                        <feature-block-group>
                            <feature-block
                                icon="barcode">
                                {{ safeTranslate('talron.login.featureText1') }}
                            </feature-block>
                            <feature-block
                                icon="swap-arrows">
                                {{ safeTranslate('talron.login.featureText2') }}

                            </feature-block>
                        </feature-block-group>
                        <div class="info-join-link-wrapper">
                            <a @click.prevent="$refs.joinModal.showModal()" href="#" class="join-link">{{ translate('talron.login.createUserLinkCta') }}</a>
                        </div>
                    </div>
                </template>
            </layout-welcome>
        </div>
    </layout-default>

    <div class="page talron main-page uk-animation-fade uk-animation-fast"
         v-show="finalDisplayMode === 'pending'">
        <layout-default-plain>
            <div class="page talron login-page login-pending-page" style="position: relative;">
                <img
                    tabindex="0"
                    role="button"
                    :aria-label="translate('talron.login.pending.back')"
                    :title="translate('talron.login.pending.back')"
                    @click="this.finalDisplayMode = 'login'"
                    :src="getDynamicAssetUrl('images/icons/auth-back-arrow.svg')"
                    style="position: absolute; top: -20px; inset-inline-start: 0; max-width: 100%; width: auto; height: 16px; cursor: pointer;"
                    alt="">

                <div class="main-icon uk-flex uk-flex-center uk-margin-medium-bottom">
                    <img :src="getDynamicAssetUrl('images/icons/auth-page-icon.svg')" style="max-width: 100%; width: auto; height: 70px;" alt="">
                </div>

                <h1 ref="loginPendingTitle" aria-live="assertive" tabindex="0"
                    v-if="finalDisplayMode === 'pending'"
                    class="main-title tr-font-xl  uk-margin-small-bottom  uk-text-center uk-text-bold uk-text-primary">
                    {{translate('talron.login.loginPendingTitle')}}
                </h1>
                <div class="link-explain uk-margin-medium-bottom uk-text-center">
                    <p>{{translate('talron.login.loginPendingExplain1')}}</p>
                    <p><strong>XXX-XXX{{this.targetMobileDigits}}</strong></p>
                    <p>{{translate('talron.login.loginPendingExplain2')}}</p>
                </div>
                <div class="counter-container uk-margin-medium-bottom"
                     style="width: 100%; height: 100px;">
                    <div class="timer-container" v-show="showCountDown">
                        <div class="timer"
                             ref="timerAnimationElement"
                             :style="'--duration: '+optLifetimeSeconds+';--size: 100;'">
                            <div class="mask"></div>
                        </div>

                    </div>
                    <div class="timer-overlay"
                         ref="timerAnimationElement2">
                        <span>{{remainingDisplayMinutes}}:{{remainingDisplaySeconds}}</span>
                        <span class="sr-only">{{translate('talron.login.pending.timeRemainingLabel', {minutes : remainingDisplayMinutes, seconds : remainingDisplaySeconds})}}</span>
                    </div>

                </div>
                <div class="resend-container uk-margin-large-bottom uk-text-center uk-text-bold" >
                    <a href="#"
                       @click="resendOtp()"
                       style="text-decoration: underline;">
                        {{translate('talron.login.loginPendingResendLink')}}
                    </a>
                </div>
                <div class="help-container uk-margin-small-bottom uk-text-center uk-text-bold ">
                    <span>{{translate('talron.login.loginPendingHelp1')}}</span>
                    <a href="#"
                       @click.prevent="$refs.helpModal.showModal()"
                       style="text-decoration: underline;">
                    {{translate('talron.login.loginPendingHelp2')}}
                </a>
                </div>
            </div>
        </layout-default-plain>
    </div>

    <teleport to="body">
        <modal :show="false" ref="helpModal" @modal:closed="">
            <template #default>

                <div class="message uk-text-center">
                    <div class="image-block uk-flex uk-flex-center uk-margin-small-bottom ">
                        <img :src="getDynamicAssetUrl('images/icons/login-help-modal-icon.svg')" alt=""
                             style="height: 50px; width: auto;"/>
                    </div>
                    <h3 class="tr-font-title uk-text-primary uk-margin-remove-top" style="margin-bottom: 6px;">
                        {{translate('talron.login.helpModalTitle')}}
                    </h3>

                    <span class="" style="font-size: 14px;">
                        {{translate('talron.login.helpModalText',{contactNumber: $store.getters['talron/contactNumberReadable']})}}
                    </span>
                </div>
            </template>
            <template v-slot:footer="{modal}">
                <div class="footer-inner">
                    <a class="uk-button uk-button-primary"
                            type="button"
                            :href="'tel:'+$store.getters['talron/contactNumber']"
                            @click="modal.closeModal()">{{translate('talron.login.helpModalCta')}}</a>
                </div>
            </template>
        </modal>
        <modal :show="false" ref="joinModal" @modal:closed="">
            <template #default>

                <div class="message uk-text-center">
                    <div class="image-block uk-flex uk-flex-center uk-margin-small-bottom ">
                        <img :src="getDynamicAssetUrl('images/icons/login-join-modal-icon.svg')" alt=""
                             style="height: 50px; width: auto;"/>
                    </div>
                    <h3 class="tr-font-title uk-text-primary uk-margin-remove-top" style="margin-bottom: 6px;">
                        {{translate('talron.login.joinModalTitle')}}
                    </h3>

                    <span class="" style="font-size: 14px;">
                            {{translate('talron.login.joinModalText', {contactNumber: $store.getters['talron/contactNumberReadable']})}}
                        </span>
                </div>
            </template>
            <template v-slot:footer="{modal}">
                <div class="footer-inner">
                    <a class="uk-button uk-button-primary"
                       type="button"
                       :href="'tel:'+$store.getters['talron/contactNumber']"
                       @click="modal.closeModal()">{{translate('talron.login.joinModalCta')}}</a>
                </div>
            </template>
        </modal>
    </teleport>
</template>



<script>


import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
import {useStore, mapGetters} from "vuex";
import asyncOperations from '@/client/extensions/composition/asyncOperations.js';



export default {
    layout    : false,
    middleware: {
        requireTalronGuest : {},
    },
    setup (props, context) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        let {meta}                                 = useMeta({title: useI18n().t('talron.login.browserTitle')}).meta

        return {
            asyncOps, asyncOpsReady, asyncStatus,meta,
        }
    },
    data() {
        return {
            socket : null,
            finalDisplayMode: 'login',
            loginFormData: {
                personalId: config.talron.useDummyUserPersonalId ? '316159003' : '',
                socketUUID: null,
                successBehaviour: 'desktop'
            },
            formConfig: {
                fields : {
                    personalId: {
                        name       : 'personalId',
                        label      : false,
                        placeholder: 'talron.login.personalIdFieldPlaceholder',
                        type       : 'text',
                        validation : {
                            required         : {},
                            israelyPersonalId: {},
                        }
                    },
                    socketUUID: {
                        name       : 'socketUUID',
                        label      : false,
                        type       : 'hidden',
                        disabled : true,
                    },
                    successBehaviour: {
                        name       : 'successBehaviour',
                        label      : false,
                        type       : 'hidden',
                        disabled : true,
                    }
                },
                buttons: {
                    'submit': {
                        'type': 'alt',
                        'text': 'talron.login.buttonCta'
                    }
                }
            },
            asyncData : {
                /*
                 summaryData: {
                 target: 'project/summary',
                 data: {
                 'from': computed(() => {
                 let d = new Date();
                 d.setFullYear( d.getFullYear() - 1);
                 d.setDate(1);
                 return d.toISOString().split('T')[0]
                 })
                 }
                 },
                 statuses: 'project/status'
                 */
            },
            targetMobileDigits : '8457',
            optLifetimeSeconds: config.talron.otpLifetime,
            elapsed: 0,
            countdownInterval: null,
            showCountDown : true,
        }
    },
    components: {},
    computed  : {
        ...mapGetters({
                          'userName'      : 'talron/userName',
                          'userPersonalId': 'talron/userPersonalId',
                          'userId'        : 'talron/userId',
                      }),
        remainingSeconds () {
            let result = this.optLifetimeSeconds - this.elapsed;
            return result > 0 ? result : 0;
        },
        remainingDisplayMinutes () {
            return String(Math.floor(this.remainingSeconds/60)).padStart(2, '0');
        },
        remainingDisplaySeconds () {
            return String(Math.floor(this.remainingSeconds % 60)).padStart(2, '0');
        },

        mainBlockBackgroundImageUrlCss() {
            let src = this.getDynamicAssetUrl('images/backgrounds/scan-bg-desktop.webp');
            return `url('${src}')`;
        },
        timeOfDayGreet() {
            let hour = (new Date()).getHours();
            if (hour >= 3 && hour <= 11) {
                return this.translate('talron.welcome.goodMorning');
            }

            if (hour >= 12 && hour <= 15) {
                return this.translate('talron.welcome.goodNoon');
            }

            if (hour >= 15 && hour <= 17) {
                return this.translate('talron.welcome.goodAfterNoon');
            }

            return this.translate('talron.welcome.goodEvening');
        }
    },
    methods   : {
        async handlePersonalIdVerified(eventData) {
            if ( ! eventData.result || ! eventData.result.status) {
                this.$s.ui.notification(this.translate('core.errorOccurredGeneric'), 'error');
            }

            if (eventData.result.data.status !== '1') {
                return this.$s.ui.notification(this.translate('talron.login.personalIdRejectedNotification'), 'error');
            }

            await this.asyncOps.socket.init();

            this.targetMobileDigits = eventData.result.data.phoneNumberLastDigits;
            this.finalDisplayMode = 'pending';
            await utilities.wait(200);
            try {
                this.$refs.loginPendingTitle.focus();
            } catch(e) {
                console.log(e);
            }
        },
        handleCountdownExpired () {
            this.$s.ui.notification(this.translate('talron.login.pendingWaitTimeExpired'), 'danger');
            this.finalDisplayMode = 'login';
            this.resetCountdown();
        },
        resetCountdown () {
            this.elapsed = 0;
            clearInterval(this.countdownInterval);
        },
        startCountdown () {
          this.showCountDown = false;
          // the good old setTimeout works best
            setTimeout(() => {
                this.showCountDown = true;
            }, 5);
            this.countdownInterval = setInterval(() => {
                this.elapsed = this.elapsed + 1;
            }, 1000);
        },
        async resendOtp() {
          let test;

          this.$store.commit('ui/showGlobalSpinner');
          test = await this.$refs.loginStep1Form.submit();
          this.$store.commit('ui/hideGlobalSpinner');

          if (test.isError) {
             return this.$s.ui.notification(this.translate('talron.login.pending.resendError'),'success');
          }

          this.resetCountdown();
          this.startCountdown();
          this.$s.ui.notification(this.translate('talron.login.pending.resendSuccess'),'success');
        }
    },
    async mounted() {

        // log the window size in our state
        if (window) {
            if (window.screen.width < 1024) {
                this.loginFormData.successBehaviour = 'mobile'
            } else {
                this.loginFormData.successBehaviour = 'desktop'
            }
        }


        // await socket
        await this.asyncOps.socket.init();
        this.socket = this.asyncOps.socket;
        await this.socket.waitForUUID();
        this.loginFormData.socketUUID = this.socket.uuid;
        this.socket.on('loginVerified', (data) => {
             this.$store.commit('talron/setUser', data);
             this.$s.ui.notification(this.translate('talron.login.loginStage2SuccessNotificationMobile') , 'success');
             this.$router.push(config.user.loginRedirect);
        });


        // console.log('asyncOps socket', this.asyncOps.socket);
        // await this.asyncOps.socket.init();
        // this.asy`ncOps.socket.emit('test', 'test data');
        // ch
        //  this.loginFormData.successBehaviour
    },
    beforeUnmount() {
        this.resetCountdown();
        this.socket.off('loginVerified');
    },
    watch: {
        finalDisplayMode (newVal) {
            if (newVal === 'pending') {
                this.resetCountdown();
                this.startCountdown();
            } else {
                this.resetCountdown();
            }
        },
        elapsed (newVal) {
            if (newVal >= this.optLifetimeSeconds) {
                this.handleCountdownExpired();
            }
        }
    },

}
</script>

<style lang="scss" scoped>

.welcome-title {
    font-size     : var(--font-xl);
    text-align    : center;
    font-weight   : bold;
    line-height   : 1.1;
    margin-top    : 2px;
    margin-bottom : 18px;
    color         : var(--global-background);

    @media (min-width : 1200px) {
        margin-bottom : 20px;
        margin-top    : 20px;
        color         : var(--global-color);
    }
}

.login-explain {
    font-size     : var(--font-s);
    font-weight   : 500;
    margin-bottom : 16px;
    color         : var(--global-background);

    @media (min-width : 1200px) {
        font-size     : var(--font-m);
        color         : var(--global-color);
        font-weight   : 700;
        margin-bottom : 16px;
    }
}

.help-link-wrapper {
    margin-top  : 14px;
    font-weight : bold;

    a {
        opacity         : 0.75;
        color           : var(--global-inverse-color);
        text-decoration : underline;

        &:hover {
            text-decoration : none;
        }
    }

    @media (min-width : 1200px) {
        margin-top : 14px;
        font-size  : 14px;


        a {
            color           : var(--global-primary-background-dark);
            text-decoration : underline;

            &:hover {
                text-decoration : none;
            }
        }

    }
}

.info-block-inner {
    padding-top    : 34px;
    padding-bottom : 44px;

    @media (min-width : 1200px) {
        padding-bottom : 0px;
    }

    .info-join-link-wrapper {
        display         : flex;
        justify-content : center;
        margin-top      : 30px;

        a {
            font-weight     : bold;
            text-decoration : underline;

            &:hover {
                text-decoration : none;
            }
        }
    }
}

</style>

<style lang="scss">

.form--intro-form {
    width         : 100%;
    max-width     : 500px;
    margin-top    : 0 !important;
    margin-bottom : 0 !important;


    @media (min-width : 1200px) {

    }

    .fields {
        margin-bottom : 0 !important;
    }

    .uk-button {
        display         : flex;
        margin          : 27px auto 0 auto; // margins collapse with form input wrapper
        align-items     : center;
        justify-content : center;

        @media (min-width : 1200px) {
            margin-top : 35px; // margins collapse with form input wrapper

        }
    }
}

.link-explain {
    font-size: 19px;
    line-height: 1.1;

    p {
        margin-top: 0;
        margin-bottom: calc(var(--global-margin) * 0.5);

        strong {
            font-size: 21px;
        }
    }
    @media screen and (max-width: 767px) {
        font-size: 17px;

        strong {
            font-size: 19px;
        }
    }

}

.counter-container {
    position: relative;
    height: 100px;
    width: 100px;
}

.timer-container {
    position: absolute;
    height: 100px;
    width: 100px;
    flex: 100px;
    margin-right: -50px;
    margin-inline-start: -50px;
    right: 50%;
    inset-inline-start: 50%;
    transform: scaleX(-1);
}

.timer-overlay {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 94px;
    width: 94px;
    flex: 94px;
    top: 3px;
    z-index: 2;
    border-radius: 50%;
    background: var(--global-background);
    margin-right: -47px;
    margin-inline-start: -47px;
    right: 50%;
    inset-inline-start: 50%;

    font-size: 16px;
    letter-spacing: 1.3px;

    @media screen and (min-width: 767px) {
        font-size: 18px;
    }

}

.timer {
    position: relative;
    background: -webkit-linear-gradient(left, var(--global-primary-background) 50%, var(--global-muted-background) 50%);
    border-radius: 100%;
    height: calc(var(--size) * 1px);
    width: calc(var(--size) * 1px);
    -webkit-animation: time calc(var(--duration) * 1s) steps(1000, start) reverse forwards;
    -webkit-mask: radial-gradient(transparent 0%,#000 0%);
    mask: radial-gradient(transparent 0%,#000 0%);
}
.mask {
    border-radius: 100% 0 0 100% / 50% 0 0 50%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
    -webkit-animation: mask calc(var(--duration) * 1s) steps(500, start)  reverse forwards;
    -webkit-transform-origin: 100% 50%;
}
@-webkit-keyframes time {
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@-webkit-keyframes mask {
    0% {
        background: var(--global-muted-background);
        -webkit-transform: rotate(0deg);
    }
    50% {
        background: var(--global-muted-background);
        -webkit-transform: rotate(-180deg);
    }
    50.01% {
        background: var(--global-primary-background);
        -webkit-transform: rotate(0deg);
    }
    100% {
        background: var(--global-primary-background);
        -webkit-transform: rotate(-180deg);
    }
}


.login-page {
    .info-block-mobile {
        &::before {
            content : ' ';
            position: absolute;
            z-index: 0;
            background: white;
            left: 0;
            top: -30px;
            right: 0;
            width: 100%;
            height: 30px;
        }
    }
}
</style>



