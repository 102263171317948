import {reactive, watchEffect} from 'vue';

const waitUntil = async (targets = [], values =[], callback = null) => {
    throw new Error('todo: waituntil');
    await new Promise((resolve) => {
        watchEffect(() => {
            let finish = false;


            // start case where we compare only one target to one or an array of values
            if ( ! Array.isArray(values)) {
                values = [values];
            }

            if ( ! Array.isArray(targets)) {
                console.log(targets, values);
               if (values.includes(targets)) {
                   resolve();
               }

               return;
            }

            // end case of one target. start many targets

            if (finish) {
               // resolve();
            }

        });
    });

    return true;
};

export {
    waitUntil
};
