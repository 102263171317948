<template>
    <div class="page talron " style="background: white;">

talron - default page

        <div style="direction:ltr; text-align: left;">
            <form-button >test</form-button>
            <h4>Todo</h4>
            <h6>Base CSS</h6>
            <ul>
                <li><span><span uk-icon="check"></span>uikit variable overrides - colors</span></li>
                <li><span><span uk-icon="check"></span>fonts</span></li>
                <li><span><span uk-icon="check"></span> Implement typography, color utilities with tr- or uk- prefix</span></li>

            </ul>
            <h6>Components</h6>
            <ul>
                <li>
                    <span><span uk-icon="check"></span>field override</span>
                </li>
                <li>
                    <span><span uk-icon="check"></span>button uk hook, allow yellow version</span>
                </li>
                <li>
                    <span><span uk-icon="check"></span>field override, uk override, allow image based icon (start and end)</span>
                </li>
                <li>
                    <span><span uk-icon="check"></span>feature block link (appears on login page, below contact)</span>
                </li>
                <li>
                    <span><span uk-icon="check"></span>action button (as appears on battery state page)</span>
                </li>
                <li>
                    <span><span uk-icon="check"></span>title separator (as appears on battery state page, above action buttons)</span>
                </li>
                <li>
                    <span><span uk-icon="check"></span>yellow progress bar (as appears on battery state page)</span>
                </li>
                <li>
                    <span><span uk-icon="check"></span>ui kit modal css hook, to make appearance similar to other modals</span>
                </li>
                <li>
                    <span><span uk-icon="check"></span>custom modal, which allows custom HTML input used by parent (modals are demanding and have different output in desktoip and mobile, including fields)</span>
                </li>

            </ul>
            <h6>Layouts</h6>
            <ul>
                <li>
                    <span><span uk-icon="minus"></span>Login / dashboard (= scan) layout</span>
                </li>
                <li>
                    <span><span uk-icon="minus"></span>Battery state layout (also for battery after action)</span>
                </li>
                <li>
                    <span><span uk-icon="minus"></span> process layout (desktop has yellow band, mobile has blue band covering the menu) </span>
                </li>
            </ul>

        </div>

<br>
        ================

        <div class="demo-wrapper uk-border-radius uk-padding"
             style="max-width: 600px;margin: 30px auto;  border: 1px solid #ececec;box-shadow:0 0 12px 1px rgba(0,0,0,0.1);">
            <div class="uk-padding uk-border-radius uk-margin-bottom" style="border: 1px solid gray;">
                <h3>דמו - טיפוגרפיה</h3>
                <p class="tr-font-title">טקסט כותרת - לורם</p>
                <p class="tr-font-xl">טקסט גדול מאוד - לורם</p>
                <p class="tr-font-l">טקסט גדול - לורם</p>
                <p class="tr-font-m">טקסט רץ, בינוני - לורם</p>
                <p class="tr-font-s">טקסט קטן - לורם</p>
                <p class="tr-font-xs">טקסט קטן מאוד - לורם</p>
                <br>
            </div>

            <div class="uk-padding uk-border-radius uk-margin-bottom" style="border: 1px solid gray;">
                <h3>דמו - שדה בטופס</h3>
                <form-input :auto-translate="false"
                            label="כותרת שדה"
                            type="text"
                            value="תוכן השדה"
                            wrapper-style="max-width: 400px;"
                ></form-input>
                <form-input :auto-translate="false"
                            label="שדה תקלה"
                            type="text"
                            value="123456"
                            error-message="יש להזין מספר זהות מלא כולל ספרת ביקורת"
                            wrapper-style="max-width: 400px;"
                ></form-input>
                <form-input :auto-translate="false"
                            label="שדה עם איקון בסוף"
                            type="text"
                            value="תוכן השדה"
                            icon-end="barcode"
                            wrapper-style="max-width: 400px;"

                ></form-input>
            </div>

            <div class="uk-padding uk-border-radius uk-margin-bottom" style="border: 1px solid gray;">
                <h3>דמו - כפתורים</h3>

                <div class="uk-margin-small-bottom">
                    <form-button>אישור החלפה</form-button>
                </div>
                <div>
                    <label>כפתור ALT</label> <br>
                    <form-button type="alt" >אלטרנטיבי</form-button>
                </div>

            </div>

            <div class="uk-padding uk-border-radius uk-margin-bottom" style="border: 1px solid gray;">
                <h3>בלוק פיצ'ר, קבוצת בלוק פיצ'רים</h3>

                <feature-block-group>
                    <feature-block
                        icon="barcode"
                        :navAction="{name: 'index'}"
                        text="talron.demo.featureBlock1">
                    </feature-block>
                    <feature-block
                        icon="swap-arrows">
                        {{safeTranslate('talron.demo.featureBlock2')}}

                    </feature-block>
                </feature-block-group>

            </div>

            <div class="uk-padding uk-border-radius uk-margin-bottom" style="border: 1px solid gray;">
                <h3>כפתורי פעולה</h3>

                <action-button-group>
                    <action-button
                        icon="barcode"
                        :navAction="{name: 'index'}"
                        text="talron.demo.actionButton1">

                    </action-button>
                    <action-button
                        icon="swap-arrows">
                        {{safeTranslate('talron.demo.actionButton2')}}
                    </action-button>
                    <action-button
                        icon="reload">
                        {{safeTranslate('talron.demo.actionButton3')}}
                    </action-button>
                </action-button-group>

            </div>

            <div class="uk-padding uk-border-radius uk-margin-bottom" style="border: 1px solid gray;">
                <h3>מפרידי כותרת</h3>

                <title-separator text="talron.demo.titleSeparatorText"></title-separator>

                <div class="uk-background-secondary uk-padding uk-margin-top uk-margin-bottom">
                    <title-separator theme="light" text="talron.demo.titleSeparatorText"></title-separator>
                </div>
            </div>

            <div class="uk-padding uk-border-radius uk-margin-bottom" style="border: 1px solid gray;">
                <h3>קו התקדמות</h3>

                <progress-bar
                    label="talron.demo.warrantyDuration"
                    start-value="0"
                    current-value="5"
                    end-value="12"
                    start-label="09/2020"
                    end-label="09/2021"></progress-bar>

            </div>

            <div class="uk-padding uk-border-radius uk-margin-bottom" style="border: 1px solid gray;">
                <h3>מודאל מערכת</h3>

                <button-group class="uk-flex-wrap uk-flex-gap-s">
                    <form-button @click="showUIkitDemoModal = true">הצג מודאל מקוסטם</form-button>
                    <form-button @click="showSystemModal">הצג מודאל מערכת</form-button>
                </button-group>

                <modal
                :show="showUIkitDemoModal">
                    <template #default>
                        <div class="image-block uk-flex uk-flex-center" style="margin-bottom: 8px;">
                            <img :src="getDynamicAssetUrl('images/battery-success.svg')" alt=""  style="height: 47px; width: auto;margin: 0 auto;"/>
                        </div>
                        <div class="message uk-text-center">
                            <h3 class="tr-font-title uk-text-success " style="margin-bottom: 3px;">ריענון המצבר בוצע בהצלחה!</h3>
                            <span class="tr-font-small uk-text-success">מק"ט המצבר - 229938878472-2234</span>
                        </div>
                    </template>
                    <template #footer>

                           <div class="footer-inner">
                               <button class="uk-button uk-button-primary"
                                       type="button"
                                       @click="showUIkitDemoModal = false">{{safeTranslate('talron.demo.uikitModalDismiss')}}</button>
                               <div class="link-container ">
                                   <a href="#" @click.prevent="" class="uk-text-bold" style="text-decoration: underline; ">חזרה למסך הבית</a>
                               </div>
                           </div>

                    </template>


                </modal>

            </div>
        </div>


    </div>
</template>



<script>
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
import {useStore} from "vuex";
import asyncOperations from '@/client/extensions/composition/asyncOperations.js';

// TODO: generate embed code (finish)
// todo: deploy
export default {
    layout    : 'default',
    middleware: {},
    setup (props, context) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        let {meta}                                 = useMeta({title: useI18n().t('talron.defaultPage.mainTitle')}).meta

        return {
            asyncOps, asyncOpsReady, asyncStatus,meta
        }
    },
    data() {
        return {
            showUIkitDemoModal: false,
            asyncData        : {
                /*
                 summaryData: {
                 target: 'project/summary',
                 data: {
                 'from': computed(() => {
                 let d = new Date();
                 d.setFullYear( d.getFullYear() - 1);
                 d.setDate(1);
                 return d.toISOString().split('T')[0]
                 })
                 }
                 },
                 statuses: 'project/status'
                 */
            },
        }
    },
    components: {},
    computed  : {

    },
    methods   : {
        showSystemModal() {
            this.$s.ui.modal.alert('כותרת','מודאל מערכת עשוי להיות מוצג בכל מיני מצבים דרך הממשק של ספרון.');
        }
    },
    mounted() {

    },

    watch: {

    },

}
</script>

<style lang="scss" scoped>


</style>




