<template>
    <div class="page talron main-page scan-page">

        <layout-solid-blob title="talron.qna.viewTitle" >

            <div class="qna-container" >
                <div class="question uk-flex uk-flex-column uk-animation-fade "
                     v-if="items.length > 0"
                     v-for="(question, index) of items"
                     :key="index">
                    <div class="question-head uk-flex uk-flex-between"

                         @click="currentItemIndex === index ?  currentItemIndex = false : currentItemIndex = index"
                         :class="{active : currentItemIndex === index}">

                        <span class="question-text"
                              role="button"
                              tabindex="0"
                              :aria-expanded="index === currentItemIndex">{{question.question}}</span>
                        <span class="icon" uk-icon="talron-qna-plus" v-if="index !== currentItemIndex"></span>
                        <span class="icon" uk-icon="talron-qna-minus" v-if="index === currentItemIndex"></span>
                    </div>
                    <div class="answer uk-animation-fade" v-show="index === currentItemIndex">
                        <strong>{{translate('talron.qna.answerGeneralLabel')}}</strong>
                        <div v-html="question.answer"></div>
                    </div>
                </div>
                <div class="qna-loader" v-else>
                    <skeleton template="lines" :count="5"></skeleton>
                </div>
            </div>


        </layout-solid-blob>

    </div>
</template>


<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import {useStore} from "vuex";
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";

export default {
    layout    : 'default',
    middleware: {

    },

    setup (props, context) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        let {meta}                                 = useMeta({title: useI18n().t('talron.qna.pageTitle')}).meta

        return {
            asyncOps, asyncOpsReady, asyncStatus,meta
        }
    },
    data() {
        return {
            currentItemIndex: false,
            items: [],
            asyncData: {
                items: 'content/qna'
            }
        }
    },
    components: {

    },
    computed  : {


    },
    methods   : {
        async handleGetBatteryInfoSuccess(eventData) {
            let data = eventData.result.data;

            if (data.status != '1') {
                return this.$s.ui.notification(this.translate('talron.scan.scanGeneralError'), 'error');
            }

            this.$store.commit('talron/setBattery', {...data});
            this.$router.push({name: 'battery'});
        }
    },
    mounted() {

    },

    watch: {},

}
</script>

<style lang="scss" scoped>
.qna-container {
    margin: auto;
    max-width: 800px;

    .question {
        padding: calc(var(--global-margin) * 1.2) 0;
        border-bottom: 1px solid var(--global-medium-gray);


        &:first-of-type {
            padding-top: 0;
        }
    }

    .question-head {
        cursor: pointer;
        gap: var(--global-margin);

        .icon {
            position: relative;
            top: 5px;
        }

        .question-text {
            margin: 0;
            font-size: 14px;

            @media (min-width: 1200px) {
                font-size: 20px;
            }
        }

        &.active {
            .question-text {
                font-weight: bold;
            }
        }
    }

    .answer {
        font-size: 14px;

        @media (min-width: 1200px) {
            font-size: 16px;
        }

        strong {
            display: block;
            margin-top: calc(var(--global-margin) * 1.2);
        }

        p {
            margin: 0;
        }

        a {

        }
    }

}



</style>

<style lang="scss">
.answer a {
    // doesnt work while scoped probably because of v-html
    font-weight: bold;
}
</style>



