<template>
    <div class="page talron main-page scan-page">

        <layout-solid-blob title="talron.contact.viewTitle" >
            <div class="contact-methods-wrapper uk-margin-auto uk-flex uk-flex-column">
                <div class="contact-method-wrapper"
                     v-if="contactMethods.length > 0"
                     v-for="(method) of contactMethods">

                    <a  v-if="method.link && method.link !== ''"
                        target="_blank"
                        :href="method.link"
                        :aria-label="method.screenReaderText"
                        :class="{'web-link': method.isExternalWebLink == 1, 'linkable' : (method.link && method.link !== '')}"
                        class="contact-method uk-flex uk-flex-center uk-flex-middle uk-flex-column"
                    >
                        <span class="contact-icon"
                              :uk-icon="`icon: ${method.icon};size: 1.5`"></span>
                        <span class="contact-method-text">{{method.content1}}</span>
                        <span class="contact-method-text contact-method-text--2" v-if="method.content2 && method.content2 != ''">{{method.content2}}</span>
                    </a>

                    <span  v-else
                           :aria-label="method.screenReaderText"
                        :class="{'web-link': method.isExternalWebLink == 1, 'linkable' : (method.link && method.link !== '')}"
                        class="contact-method uk-flex uk-flex-center uk-flex-middle uk-flex-column"
                    >
                        <span class="contact-icon"
                             role="img"
                              :alt="method.screenReaderText ? method.screenReaderText : false"
                              :aria-label="method.screenReaderText ? method.screenReaderText : null"
                              :uk-icon="`icon: ${method.icon};size: 1.5`"></span>
                        <span class="contact-method-text">{{method.content1}}</span>
                        <span class="contact-method-text contact-method-text--2" v-if="method.content2 && method.content2 != ''">{{method.content2}}</span>
                    </span>

                    <div class="contact-separator"></div>


                </div>


                <skeleton v-else template="story" :count="4"></skeleton>

                <form-button
                    @click="$router.back()"
                    v-if="contactMethods.length > 0"
                    class="uk-margin-large-top">חזרה</form-button>
            </div>
        </layout-solid-blob>

    </div>
</template>



<script>
import asyncOperations from "@/client/extensions/composition/asyncOperations";
import {useStore} from "vuex";
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
export default {
    layout    : 'default',
    middleware: {

    },
    setup (props, context) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        let {meta}                                 = useMeta({title: useI18n().t('talron.contact.pageTitle')}).meta

        return {
            asyncOps, asyncOpsReady, asyncStatus,meta
        }
    },
    data() {
        return {
            temp : [],
            contactMethods: [

            ],
            /*
             {
             icon: 'talron-location',
             content1: 'טלרון מצברים בע"מ',
             content2: 'המעיין 7, מודיעין',
             link: false,
             },
             {
             icon: 'talron-whatsapp',
             content1: '052-6956194',
             content2: false,
             link: 'https://wa.me/0526956194',
             },
             {
             icon: 'talron-phone',
             content1: '1-700-700-130',
             content2: false,
             link: 'tel:1700700130',
             },
             {
             icon: 'talron-service-station',
             content1: 'תחנות שירות טלרון',
             content2: false,
             link: 'https://google.com',
             isExternalWebLink: true,
             }
             */
            asyncData: {
                contactMethods: 'content/contact'
            }
        }
    },
    components: {

    },
    computed  : {


    },
    methods   : {

    },
    mounted() {

    },

    watch: {},

}
</script>

<style lang="scss" scoped>
.contact-methods-wrapper {
    justify-content: center;
    align-items: center;

    .contact-method {
        display: flex;
        position: relative;
        color: var(--global-color);
        padding-top: calc(var(--global-margin) * 0.6);
        padding-bottom: calc(var(--global-margin) * 0.6);
        margin-top: calc(var(--global-margin) * 0.6);
        margin-bottom: calc(var(--global-margin) * 0.6);
        font-size: 15px;
        max-width: 200px;

        &:not(.linkable) {

            &:hover {
                text-decoration: none;
                cursor: default;
            }
        }

        @media (min-width:1200px) {
            font-size: 20px;
        }

        &.web-link {
            .contact-method-text {
                color: var(--global-link-color);
                text-decoration: underline;

                &:hover{
                    text-decoration: none;
                }
            }
        }

        .contact-icon {
            margin-bottom: calc(var(--global-margin) * 0.5);
        }



    }

    .contact-separator {
        width: 125px;
        min-height: 2px;
        background-color: #fcba38;
        z-index: 2;
    }


    .contact-method-wrapper:last-of-type{
        .contact-separator {
            display: none;
        }


    }

}



</style>

<style lang="scss">

</style>



