<template>
    <div class="page talron main-page">
      <div class="test" style="height: 100%; flex: 100%; width: 100%;">
          test
      </div>

    </div>
</template>


<script setup>


const props = defineProps({});


</script>
<script>
import {useMeta} from "vue-meta";
import {useI18n} from "vue-i18n/index";
import {useStore, mapGetters} from "vuex";
import asyncOperations from '@/client/extensions/composition/asyncOperations.js';
import {computed} from 'vue';
export default {
    layout    : 'default',
    middleware: {
        // todo: redirect if no talron user
    },
    setup (props, context) {
        let {asyncOps, asyncOpsReady, asyncStatus} = asyncOperations(props, useStore());
        let {meta}                                 = useMeta({title: useI18n().t('talron.defaultPage.mainTitle')}).meta

        return {
            asyncOps, asyncOpsReady, asyncStatus,meta
        }
    },
    data() {
        return {
            asyncData: {
                /*
                 summaryData: {
                 target: 'project/summary',
                 data: {
                 'from': computed(() => {
                 let d = new Date();
                 d.setFullYear( d.getFullYear() - 1);
                 d.setDate(1);
                 return d.toISOString().split('T')[0]
                 })
                 }
                 },
                 statuses: 'project/status'
                 */
            },
        }
    },
    components: {

    },
    computed  : {
        ...mapGetters({
                          'userName'      : 'talron/userName',
                          'userPersonalId': 'talron/userPersonalId',
                          'userId'        : 'talron/userId',
                      }),
        mainBlockBackgroundImageUrlCss() {
            let src = this.getDynamicAssetUrl('images/backgrounds/scan-bg-desktop.webp');
            return `url('${src}')`;
        },
        timeOfDayGreet() {
            let hour = (new Date()).getHours();
            if ( hour >= 3 && hour <= 11) {
                return this.translate('talron.welcome.goodMorning');
            }

            if ( hour >= 12 && hour <= 15) {
                return this.translate('talron.welcome.goodNoon');
            }

            if ( hour >= 15 && hour <= 17) {
                return this.translate('talron.welcome.goodAfterNoon');
            }

            return this.translate('talron.welcome.goodEvening');
        }
    },
    methods   : {},
    mounted() {

    },

    watch: {},

}
</script>

<style lang="scss" scoped>

.desktop-code-explain {
    font-size: var(--font-m);
    font-weight: 700;
    margin-bottom: 13px;
}

.profile-info, .welcome-title {
    color: var(--global-primary-background-dark);
}

.profile-info {
    @media (min-width: 1200px) {
        display         : flex;
        flex-direction  : column;
        align-items     : center;
        justify-content : center;
        font-size: 24px;

        .profile-icon {
            height: 45px;
            width: 40px;
            margin-bottom: 3px;
        }

        .profile-text {
            margin-top: -12px;
        }
    }
}

.welcome-title {
    font-size: var(--font-xl);
    font-weight: bold;
    line-height: 1;
    margin-top: 2px;
    margin-bottom: 18px;

    @media (min-width: 1200px) {
        margin-bottom: 20px;
    }

}

.battery-code-input-wrapper {
    width: 100%;
    max-width: 500px;
    margin-bottom: 10px;

    @media (min-width: 1200px) {
        max-width: 500px;
        width: 500px;
        margin-bottom: 20px;

    }

}

.scan-button-group {
    margin-bottom: 10px;

    @media (min-width:1200px) {
        margin-bottom: 0;
    }
}
.steps {
    display: block;
    padding: 10px 30px 0 30px;

    .step {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        gap: 15px;
        margin-bottom: 25px;

        &:last-of-type {
            margin-bottom: 0;
        }

        .step-number {
            display: flex;
            height: 24px;
            width: 24px;
            flex: 0 0 24px;
            border-radius: 50%;
            border: 2px solid var(--global-inverse-color);
            align-items: center;
            justify-content: center;
            text-align: center;
            line-height: 1;
            font-weight: bold;
            font-size: var(--font-m);
        }

        .step-content {
            font-weight: 500;
            display: inline-block;
        }
    }

    // this includes override for the nested step. it is batter to manage it like this
    @media (min-width:1200px) {
        display: flex;
        justify-content: space-between;
        margin-top: 45px;
        margin-bottom: 10px;

        .step {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            width: 150px;

            .step-number {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                width: 30px;
                flex: 0 0 30px;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                color: var(--global-inverse-color);
                border-radius: 50%;
                background-color: var(--global-primary-background);
            }

            .step-content {
                font-weight: 600;
                text-align: center;
                color: var(--global-primary-background-dark);
            }
        }
    }
}

</style>

<style lang="scss">
// NOT SCOPED
.battery-code-input-wrapper {
    .uk-input {
        border-color: var(--global-color);
        font-weight: bold;

        &::-webkit-input-placeholder,
        &::placeholder {
            border-color: var(--global-color);
            font-weight: bold;
            color: var(--global-color);
            opacity: 0.8;
        }
    }

    .uk-form-controls .uk-form-icon {
        color: var(--global-color);
        margin-inline-end: 10px;

        @media (min-width: 1200px) {
            display: none;
        }
    }
}

// we override like this to allow meeting design requirements with a single slot output
.info-block .title-separator {
    .content-text {
        background: transparent!important;
    }

    @media (min-width: 1200px) {
        .content {
           color: var(--global-color)!important;
        }

        .line {
            background-color: var(--global-color)!important;
        }
    }
}

</style>



