import Home from '@/client/views/Home.vue'
import asyncFactory from '@/client/extensions/modules/asyncComponentFactory.js'


export default [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta : {
            'layout' : 'default',
        }
    },



];
